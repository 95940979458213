import request from './_https';
// querystring 在 node 14 後已經棄用，改為 URLSearchParams
import setQueryString from '../tools/helpers';

/** ------------------------------
 *  InfluxDB - Realtime Data
 *  --------------------------- */
export const api_Device_Realtime_Data   = (params, query = undefined) => request('get', `/devices/${params.hashID}/realtime${setQueryString(query)}`);
export const api_Gateway_Realtime_Data  = (params, query = undefined) => request('get', `/gateways/${params.hashID}/realtime${setQueryString(query)}`);

/** ------------------------------
 *  Celebi - Raw Data
 *  --------------------------- */
export const api_Device_Raw_Data = (params, query = undefined) => request('get', `/devices/${params.hashID}/raw${setQueryString(query)}`);

/** ------------------------------
 *  AccessPoint Log
 *  --------------------------- */
export const api_AccessPoint_Logs = (params, query = undefined) => request('get', `/accesspoints/${params.hashID}/logs${setQueryString(query)}`);
