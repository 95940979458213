
const config = {


    // ------------------------------------------------------------------
    // tool function
    // ------------------------------------------------------------------

    /**
     * getAssetsURL: 根據 baseURL 取得素材位置
     * @param {string} url - 素材相對位置
     */
    getAssetsURL: function (url)
    {
        // if ( process.env.NODE_ENV === 'production' ) return process.env.BASE_URL + url;
        // return '/' + url;
        return process.env.BASE_URL + url;
    },

    checkURL: function (assetType, url)
    {
        if (url && url !== '') return url;  // url 非 undefined 且非空字串，就直接回傳該位址

        let _asset = '';
        switch (assetType)
        {
            case 'avatar':      _asset = 'https://www.gravatar.com/avatar/default?s=150&r=pg&d=mm'; break;
            case 'pic':         _asset = 'img/default/project.png';     break;
            case 'logo':        _asset = 'img/brand/Beehive_Logo.png';  break;
            case 'photo':       _asset = 'img/default/photo.png';       break;
            case 'cropperiod':  _asset = 'img/default/cropPeriod.png';  break;
            case 'coming_soon': _asset = 'img/brand/AgriComingSoon.png'; break;
            default:            _asset = 'img/default/photo.png';       break;
        }
        return this.getAssetsURL(_asset);
    },

}

export { config }
