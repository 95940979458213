import DateTools from '@/tools/date';
import * as DeviceAPI from '@/apis/device';
import deviceUtils from '@/data/device_utils.json';

// initial state
const state = {

  utils: deviceUtils,
  list_battery_type: deviceUtils.battery_type,
  list_max_voltage: deviceUtils.battery_max_voltage,

  length_Hashid: 8,

  list_sort: [
    { value: '-id', text: 'id 降序' },
    { value: 'id', text: 'id' },

    { value: '-deviceid', text: 'deviceid 降序' },
    { value: 'deviceid', text: 'deviceid' },

    { value: '-manufacture_date', text: '出廠日期 降序' },
    { value: 'manufacture_date', text: '出廠日期' },

    { value: '-updated_at', text: '更新時間 降序' },
    { value: 'updated_at', text: '更新時間' },

    { value: '-created_at', text: '建立時間 降序' },
    { value: 'created_at', text: '建立時間' },
  ],

  list_categories: [
    { value: 'weatherbox' },
    { value: 'wand' },
    { value: 'camera' },
    { value: 'actuator' },
    { value: 'extra' },
  ],

  list_types: {
    accesspoint:
    {
      default: 'AA',
      options: {
        AA: { hashLength: 6, name: '無線熱點機' },
        AR: { hashLength: 6, name: '4G Router' },
      },
    },
    gateway: {
      default: 'GW',
      options: {
        GW: { hashLength: 6, name: '插電版本閘道器' },
        GA: { hashLength: 6, name: '控制版本閘道器' },
        GB: { hashLength: 6, name: '翼詠控制閘道器' },
        GC: { hashLength: 6, name: '戶外閘道器（不接電）' },
      },
    },
    weatherbox: {
      default: 'WC',
      options: {
        WE: { hashLength: 6, name: '第六代主板氣象站' },
        WC: { hashLength: 6, name: 'Davis LoRa 無線氣象站' },
        WB: { hashLength: 6, name: 'Davis Radio 無線氣象站' },
        WD: { hashLength: 6, name: '中央氣象局局屬(A0003-001)+自動(A0001-001)API' },
        WA: { hashLength: 6, name: 'Virtual 虛擬氣象站 - openweathermap' },
      },
    },
    wand: {
      default: 'FA',
      options: {
        FA: { hashLength: 6, name: '第六代主板感測器' },
        FB: { hashLength: 6, name: '第六代主板感測器2' },
        FC: { hashLength: 6, name: '第六代主板感測器3' },
        FD: { hashLength: 6, name: '第六代主板感測器4' },
        FE: { hashLength: 6, name: '第六代主板感測器5' },
        DA: { hashLength: 6, name: '第四代主板感測器' },
        DB: { hashLength: 6, name: '第四代主板感測器2' },
        DC: { hashLength: 6, name: '第四代主板感測器3' },
        DD: { hashLength: 6, name: '第四代主板感測器4' },
        // 'EA': { hashLength: 6, name: '???' },
      },
    },
    camera: {
      default: 'CB',
      options: {
        CB: { hashLength: 6, name: 'RPiZero 電池版本' },
        CA: { hashLength: 6, name: 'RPi 田間插電版本' },
        CC: { hashLength: 6, name: 'ApproCATM 版本' },
      },
    },
    actuator: {
      default: 'KA',
      options: {
        KA: { hashLength: 6, name: '電磁閥控制器' },
      },
    },
    extra: {
      default: 'XA',
      options: {
        XA: { hashLength: 6, name: '小米 溫溼度感測器' },
        XB: { hashLength: 6, name: '小米 花花草草' },
        XC: { hashLength: 6, name: '鍠麟 感測器大集合' },
        XD: { hashLength: 6, name: 'MuRata 土壤感測器' },
      },
    },
  },

  list_attribute: [
    { value: 'is_refurbish' },
    { value: 'is_rent' },
    { value: 'attention' },
  ],

  list_status: [
    { value: 'planning' },
    { value: 'wait4test' },
    { value: 'inventory' },
    { value: 'tosend' },
    { value: 'shipping' },
    { value: 'active' },
    { value: 'investigating' },
    { value: 'malfunction' },
    { value: 'waitcontact' },
    { value: 'waitback' },
    { value: 'checking' },
    { value: 'inqueue' },
    { value: 'repairing' },
    { value: 'resting' },
    { value: 'abandon' },
  ],

  sensor_params: {
    air_temperature: {},
    air_humidity: {},
    air_temperature02: {},
    air_humidity02: {},

    air_co2_temperature: {},
    air_co2_humidity: {},

    air_co: {},
    air_co2: {},
    air_nh3: {},
    air_h2s: {},

    air_pm25: {},
    air_pm10: {},

    air_pressure: {},

    wind_speed: {},
    wind_direction: {},
    rain_rate: {},
    rain_rate_raw: {},
    rainfalls: {},

    water_depth: {},

    leaf_temperature: {},
    leaf_humidity: {},

    dew_point: {},

    solar_radiation: {},
    solar_lux: {},
    solar_par: {},
    uvi: {},

    soil_temperature: {},
    soil_moisture: {},
    soil_conductivity: {},
    soil_conductivity_pore: {},
    soil_ph: {},

    'mixture_temperature ': {},

    voltage: {},
    solar_voltage: {},
    bug_num: {},
    daily_bugs: {},
    battery_percentage: {},
    voltage_to_battery_percentage: {},
    inner_temperature: {},
    rssi: {},
    snr: {},
    lqi: {},

    // WA
    rainfalls_1h: {},
    rainfalls_3h: {},
  },

};

// getters
const getters = {

  all_types: (state) => (length = 1) => {
    const _all_types = [];

    for (const _category in state.list_types) {
      for (const _type in state.list_types[_category].options) {
        const _type_code = _type.slice(0, length).toLowerCase();
        if (_all_types.indexOf(_type_code) === -1) {
          _all_types.push(_type_code);
        }
      }
    }
    return _all_types;
  },

  hashid_TO_page: (state, getters) => (hashid, page) => {
    let _route_info = { name: 'Page404' };
    let _page;

    // 檢查 page 是否為字串，是的話將第一個字母轉為大寫
    if (typeof page !== 'string') _page = undefined;
    else _page = page.charAt(0).toUpperCase() + page.slice(1).toLowerCase();

    if (hashid !== undefined && hashid.length > 0 && _page !== undefined) {
      const _t = hashid[0].toLowerCase();
      switch (_t) {
        case 'g': _route_info = { name: `Gateway ${_page}`, params: { hashID: hashid } }; break;
        case 'a': _route_info = { name: `AccessPoint ${_page}`, params: { hashID: hashid } }; break;
        case 'c':
          if (_page === 'Data') { _route_info = { name: `Camera ${_page}`, params: { hashID: hashid } }; } else { _route_info = { name: `Device ${_page}`, params: { hashID: hashid } }; }
          break;
        default:
          const _all_types = getters.all_types(1);

          // 檢查是否在分類內
          if (_all_types.indexOf(_t) !== -1) {
            _route_info = { name: `Device ${_page}`, params: { hashID: hashid } };
          } else { _route_info = { name: 'Page404' }; }
          break;
      }
    }
    return _route_info;
  },

  hashid_TO_category: (state) => (hashid) => {
    let _category;

    if (hashid !== undefined && hashid.length > 0) {
      const _t = hashid[0].toLowerCase();
      switch (_t) {
        case 'g': _category = 'gateway'; break;
        case 'w': _category = 'weatherbox'; break;
        case 'c': _category = 'camera'; break;
        case 'd':
        case 'e':
        case 'f': _category = 'wand'; break;
        case 'k': _category = 'actuator'; break;
        case 'x': _category = 'extra'; break;
        case 'a': _category = 'accesspoint'; break;
        default: _category = 'wand'; break;
      }
    }
    return _category;
  },

};

// actions
const actions = {

  get_Device_Records(context, settings) {
    return new Promise((resolve, reject) => {
      DeviceAPI.api_Get_Device_Records(settings.params, settings.query)
        .then((res) => {
          const resData = res.data;

          if (resData) {
            if (resData.records) {
              const _timezone = this.state.user.timezone; // 取得其他 namespace 的 state

              resData.records.forEach((_record) => {
                _record.record_time = DateTools.dateTimeZone(_record.record_time, _timezone, 'YYYY-MM-DD HH:mm');
                _record.updated_at = DateTools.dateTimeZone(_record.updated_at, _timezone, 'YYYY-MM-DD HH:mm');
              });
            }
          }

          resolve(resData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  get_Device_Detail(context, settings) {
    return new Promise((resolve, reject) => {
      DeviceAPI.api_Get_Device_Detail(settings.params)
        .then((res) => {
          const resData = res.data;

          if (resData) {
            if (resData.device) {
              const _timezone = this.state.user.timezone; // 取得其他 namespace 的 state

              resData.device.manufacture_date = DateTools.dateTimeZone(resData.device.manufacture_date, _timezone, 'YYYY-MM-DD');
              resData.device.updated_at = DateTools.dateTimeZone(resData.device.updated_at, _timezone, 'YYYY-MM-DD HH:mm');
              resData.device.created_at = DateTools.dateTimeZone(resData.device.created_at, _timezone, 'YYYY-MM-DD HH:mm');
            }
          }

          resolve(resData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  get_Devices(context, settings) {
    return new Promise((resolve, reject) => {
      DeviceAPI.api_Get_Devices(settings.query)
        .then((res) => {
          const resData = res.data;
          const _hashids = [];

          if (resData) {
            if (resData.devices) {
              const _timezone = this.state.user.timezone; // 取得其他 namespace 的 state

              resData.devices.forEach((_device) => {
                if (_hashids.indexOf(_device.hashid) === -1) _hashids.push(_device.hashid);

                _device.manufacture_date = DateTools.dateTimeZone(_device.manufacture_date, _timezone, 'YYYY-MM-DD');
                _device.updated_at = DateTools.dateTimeZone(_device.updated_at, _timezone, 'YYYY-MM-DD');
                _device.created_at = DateTools.dateTimeZone(_device.created_at, _timezone, 'YYYY-MM-DD');
              });
            }
          }

          // 設定 Hashid 資訊：呼叫 Service Unit API 後才返還 res
          if (_hashids.length > 0) {
            const _query = {};
            _query.hashids = _hashids.join(',');

            this.dispatch('service_unit/get_ServiceUnits', { query: _query })
              .then((r) => {
                // 把 SUID 資料填回去
                r.service_units.forEach((_suid) => {
                  const _index_of_device = _hashids.indexOf(_suid.hashid);

                  if (_index_of_device !== -1) resData.devices[_index_of_device].suid = _suid;
                });

                resolve(resData);
              })
              .catch((e) => { resolve(resData); });
          } else resolve(resData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

};

// mutations
const mutations = {

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
