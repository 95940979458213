import DateTools from '@/tools/date';
import * as ServiceUnitAPI from '@/apis/service_unit';

// initial state
const state = {

  length_SUID: 11,

  list_sort: [
    { value: '-id', text: 'id 降序' },
    { value: 'id', text: 'id' },

    { value: '-due_date', text: '保固結束 降序' },
    { value: 'due_date', text: '保固結束' },

    { value: '-updated_at', text: '更新時間 降序' },
    { value: 'updated_at', text: '更新時間' },

    { value: '-created_at', text: '建立時間 降序' },
    { value: 'created_at', text: '建立時間' },
  ],

  default_category: 'S',
  list_category: {
    G: { device_category: 'gateway', text: '閘道器' },
    A: { device_category: 'accesspoint', text: '無線熱點' },
    W: { device_category: 'weatherbox', text: '氣象站' },
    S: { device_category: 'wand', text: '感測器' },
    C: { device_category: 'camera', text: '縮時攝影' },
    K: { device_category: 'actuator', text: '控制器' },
    O: { device_category: 'extra', text: '其他裝置' },
  },

  default_type: 'bought',
  list_type: {
    bought: { text: '購買', service_text: '保固', variant: 'primary' },
    rent: { text: '租用', service_text: '租用', variant: 'info' },
    borrowed: { text: '借用', service_text: '借用', variant: 'tip' },
  },

  default_status: 'active',
  list_status: {
    active: { text: '運行中' },
    abandon: { text: '已棄用' },
  },

  // 用來暫存要顯示的 SUID
  show_suid: undefined,
};

// getters
const getters = {

  category_Can_bind_alias: (satate) => (category) => {
    if (category.toUpperCase() === 'G') return false;
    if (category.toUpperCase() === 'A') return false;
    if (category.toUpperCase() === 'K') return false;
    return true;
  },

  suid_TO_customerID: (state) => (suid) => {
    let _customerID;

    if (suid !== undefined && suid.length === state.length_SUID) {
      _customerID = parseInt(suid.slice(1, 6));
    }
    return _customerID;
  },

  suid_TO_category: (state) => (suid) => {
    let _category;

    if (suid !== undefined && suid.length === state.length_SUID) {
      const _category_code = suid[6].toUpperCase();

      if (state.list_category[_category_code] !== undefined) {
        _category = _category_code;
      }
    }
    return _category;
  },

  suid_TO_device_category: (state) => (suid) => {
    let _category;

    if (suid !== undefined && suid.length === state.length_SUID) {
      const _category_code = suid[6].toUpperCase();

      if (state.list_category[_category_code] !== undefined) {
        _category = state.list_category[_category_code].device_category;
      }
    }
    return _category;
  },

};

// actions
const actions = {

  get_ServiceUnits(context, settings) {
    return new Promise((resolve, reject) => {
      ServiceUnitAPI.api_Get_ServiceUnits(settings.query)
        .then((res) => {
          const resData = res.data;

          if (resData) {
            if (resData.service_units) {
              const _timezone = this.state.user.timezone; // 取得其他 namespace 的 state

              resData.service_units.forEach((_item) => {
                if (_item.start_date) _item.start_date = DateTools.dateTimeZone(_item.start_date, _timezone, 'YYYY-MM-DD');
                if (_item.due_date) _item.due_date = DateTools.dateTimeZone(_item.due_date, _timezone, 'YYYY-MM-DD');

                _item.updated_at = DateTools.dateTimeZone(_item.updated_at, _timezone, 'YYYY-MM-DD HH:mm');
                _item.created_at = DateTools.dateTimeZone(_item.created_at, _timezone, 'YYYY-MM-DD HH:mm');

                _item.device_category = this.getters['service_unit/suid_TO_device_category'](_item.suid);

                if (_item.target) {
                  _item.target.manufacture_date = DateTools.dateTimeZone(_item.target.manufacture_date, _timezone, 'YYYY-MM-DD');
                  _item.target.updated_at = DateTools.dateTimeZone(_item.target.updated_at, _timezone, 'YYYY-MM-DD HH:mm');
                  _item.target.created_at = DateTools.dateTimeZone(_item.target.created_at, _timezone, 'YYYY-MM-DD HH:mm');
                }
              });
            }
          }

          resolve(resData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

};

// mutations
const mutations = {

  set_Show_SUID(state, suid) {
    state.show_suid = suid;
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
