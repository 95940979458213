import Axios from 'axios';
import { api_Get_Backend_Version } from '@/apis/management';

// initial state
const state = {
  version: '',
  version_FrontEnd: process.env.VUE_APP_VERSION,
  modal_document: false,
  modal_transport_door: false,

  // [ 使用者視窗寬度 ] 用來記錄並讓元件偵聽
  // 用來告訴系統中有偵聽 system.screenWidth 的元件視窗大小有變動
  // 已於 App.vue 進行全域偵聽
  screenWidth: document.body.clientWidth,

  // [ UI 版面寬度 ] 用來記錄並讓元件偵聽
  // 用來告訴系統中用到 system.contentWidth 的元件說視窗寬度不變，但是呈現版面寬度有變動
  // 無偵聽設定，要觸發變化需自行設定：
  // 使用 this.$store.commit('system/setContentWidth', UI 版面寬度 ); 來觸發
  contentWidth: document.body.clientWidth,

  // 檢查是否已註冊 Service Worker
  // 要將網頁新增至手機業面當作捷徑，需要先註冊 Service Worker
  register_ServiceWorker: false,
  deferredPrompt: undefined,
};

// getters
const getters = {};

// actions
const actions = {
  get_Version(context) {
    return new Promise((resolve, reject) => {
      api_Get_Backend_Version()
        .then((res) => {
          // console.log(res.data)
          context.commit('set_Version', res.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  set_ModalDocument(state, value) {
    if (value) state.modal_document = true;
    else state.modal_document = false;
  },

  set_ModalTransportDoor(state, value) {
    if (value) state.modal_transport_door = true;
    else state.modal_transport_door = false;
  },

  // 設定 Service Worker 註冊狀態
  set_DeferredPrompt(state, event) {
    state.deferredPrompt = event;
    // this.commit('system/add_to_home_screen', event)
  },
  register_ServiceWorker(state, registerStatus) {
    state.register_ServiceWorker = registerStatus;

    const that = this;
    window.addEventListener('beforeinstallprompt', (e) => {
      // console.log('beforeinstallprompt')

      // Prevent Chrome 67 and earlier from automatically showing the prompt
      // e.preventDefault();

      that.commit('system/set_DeferredPrompt', e);
    });
    window.addEventListener('appinstalled', (e) => {
      // console.log('appinstalled')
    });
  },
  // 將網頁新增至手機業面當作捷徑
  add_to_home_screen(state, data) {
    // console.log('add_to_home_screen')
    if (state.deferredPrompt !== undefined) {
      state.deferredPrompt.prompt();
      // console.log('prompt')
      state.deferredPrompt.userChoice.then((choiceResult) => {
        // console.log('User Choice')
        if (choiceResult.outcome === 'dismissed') {
          console.log('user 取消安裝至桌面');
        } else {
          console.log('user 接受安裝至桌面');
        }
      });
    }
  },

  set_Content_Width(state, data) {
    state.contentWidth = data;
  },

  set_Screen_Width(state, data) {
    state.screenWidth = data;
  },

  set_Version(state, data) {
    state.version = data.version;
  },

  set_Version_FrontEnd(state, data) {
    state.version_FrontEnd = data.version;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
