
export default {
    menu: {
        home: 'Home',
        projects: 'Projects',
        project_summary: 'Project Summary',
        history: 'History',
        params_comparison: 'Parameters Comparison',
        mix_chart: 'Mix Chart',
        comparison: 'Comparison',
        links: 'Links',
        summary: 'Summary',
        updated: 'Updated',
        refresh_data: 'Refresh Data',
        select: 'Select',
        analysis: 'Analysis',
        prediction: 'Prediction',
        management: 'Management',
        user: 'User',
        import: 'Import',
        export: 'Export',
        show: 'Show',
        hide: 'Hide',
        create: 'Create',
        upload: 'Upload',
        download: 'Download',
        message: 'Message',
        language: 'Language',
        login: 'Login',
        logout: 'Logout',
        register: 'Register',
        editting: 'Editting',
        setting: 'Setting',
        status: 'Status',
        attribute: 'Attribute',
        authority: 'Authority',
        inquire: 'Inquire',
        close: 'Close',
        email: 'Email',
        password: 'Password',
        forgot_password: 'Forgot Password',
        reset_password: 'Reset Password',
        cancel: 'Cancel',
        confirm: 'Confirm',
        delete: 'Delete',
        account: 'Account',
        user_name: 'User Name',
        user_profile: 'User Profile',
        upload_avatar: 'Upload Avatar',
        upload_image: 'Upload Image',
        select_image: 'Select Image',
        save_changes: 'Save Changes',
        last_name: 'Last Name',
        first_name: 'First Name',
        phone: 'Phone',
        address: 'Address',
        location: 'Location',
        receive_early_warning_email: 'Receive early warning email',
        accept_the_terms_and_use: 'I accept the terms and use',
        remember_me: 'Remember me',
        company_job: 'Job / Company',
        service_level: 'Service Level',
        start_date: 'Start Date',
        end_date: 'End Date',
        manufacture_date: 'Manufacture Date',
        select_date: 'Select Date',
        select_date_range: 'Select Date Range',
        time_now: 'Now',
        clean: 'Clean',
        selected: 'Selected',
        deselect: 'Deselect',
        range: 'Range',
        date_previous: 'Previous',
        date_next: 'Next',
        timezone: 'Time Zone',
        participants: 'Participants',
        role: 'Role',
        add_project_member: 'Add Project Member',
        detail: 'Detail',
        show_detail: 'Show Detail',
        related_search: 'Related Search',
        search: 'Search',
        search_result: 'Search Result',
        search_count: 'Search Count',
        project_information: 'Project Information',
        cropping_season_information: 'Cropping Season Information',
        check_dashboard: 'Check real-time dashboard',
        warning_alerts: 'Warning Alerts',
        warning_history: 'Warning History',
        system_information: 'System Information',
        device_information: 'Device Information',
        go_top: 'Top',
        toolbox: 'Toolbox',
        cover: 'Cover',
        logo: 'LOGO',
        picture: 'Picture',
        project: 'Project',
        name: 'Name',
        description: 'Description',
        type: 'Type',
        admin_note: 'Admin Note',
        admin_tools: 'Admin Tools',
        subordinate_management: 'Subordinate Management',
        project_management: 'Project Management',
        cropping_season_management: 'Cropping Season Management',
        device_management: 'Device Management',
        custom_alert_rule_management: 'Alert Rule Management',
        new_project: 'New Project',
        new_cropping_season: 'New Cropping Season',
        clone_as_new_cropping_season: 'Clone As New Cropping Season',
        clone: 'Clone',
        copy: 'Copy',
        copy_link: 'Copy Link',
        copy_report_link: 'Copy Link of this Report',
        copy_to_clipboard: 'Copy to Clipboard',
        delete_cropping_season: 'Delete Cropping Season',
        done_cropping_season: 'Set the date as finished date of this Cropping Season',
        add_device_to_the_cropping_season: 'Add Device to the Cropping Season',
        remove_device_from_the_cropping_season: 'Remove Device from the Cropping Season',
        devices_in_cropping_season: 'Devices in this Cropping Season',
        unfinished: 'Unfinished',
        finished: 'Finished',
        abandoned: 'Abandoned',
        deleted: 'Deleted',
        no_data: 'No Data',
        no_data_to_download: 'No Data to Download',
        no_description: 'No Description',
        no_content: 'No Content',
        instructions: 'Instructions',
        op_instructions: 'Instructions',
        filter_tools: 'Filter Tools',
        map_instructions_rotate_laptop: 'Shift + Drag to rotate and zoom the map around its center.',
        map_instructions_rotate_tablet: 'Use two fingers gestures to rotate and zoom the map around its center.',
        map_instructions_zoom_in: 'Zoom In',
        map_instructions_zoom_out: 'Zoom Out',
        map_instructions_zoom_reset: 'Reset zoom value & center position of the map.',
        map_instructions_rotate_reset: 'Reset rotation value of the map.',
        project_area: 'Project Area',
        field_area: 'Area',
        planting_density: 'Planting Density',
        limit: 'Limit',
        print: 'Print',
        new_comment: 'New Comment',
        comment: 'Comment',
        reply: 'Reply',
        edited: 'Edited',
        register_app_line: 'Register Line Notify',
        linked: 'Linked',
        group: 'group',
        setting_info: 'Setting Info',

        observe_time: 'Observe Time',
        weather_forecast: 'Weather Forecast',
        planting_day: 'Planting Day',
        planting_period: 'Planting Period',
        days_after_planting: 'Days After Planting',
        current_growth_stage: 'Current Growth Stage',
        days_to_next_stage: 'Days to Next Stage',
        days_left: 'Days Left',
        crop_growth_progress: 'Crop Growth Progress',
        agri_science_reports: 'Reports',
        weather_and_crop_forecast: 'Weather and Crop Forecast',
        official_warning: 'Official Warning',
        suggested_activities: 'Suggested Activities',
        suggested_preventions: 'Suggested Preventions',
        stress: 'Stress',
        farming_activity: 'Farming Activity',
        disease_pest_control: 'Disease and Pest Control',
        crop_traits : 'Crop Traits',
        expert_consult: 'Expert Consult',
        leave_a_message: 'Leave a message',
        yield_prediction: 'Yield Prediction',
        data_count: 'Data Count',
        data_table: 'Data Table',
        update_frequency: 'Update Frequency',
        per: 'per',
        per_page: 'Per Page',

        daily_change: 'Daily Change',
        statical_result: 'Statical Result',
        parameter_choose: 'Parameter Choose...',
        accumulation: 'Accumulation',
        total: 'Total',
        average: 'Average',
        midian: 'Midian',
        standard_deviation: 'Standard Deviation',
        value: 'Value',

        weather_report: 'Weather Report',
        analysis_report: 'Analysis Report',
        analysis_tools: 'Analysis Tools',
        crop_records: 'Crop Records',
        agrinote: 'AgriNote',
        field_notes: 'Field Notes',
        time_lapse_photo: 'Time-lapse photography',
        time_lapse_camera: 'Time-lapse camera',
        adversity_weather: 'Adversity Weather',
        environmental_data: 'Environmental Data',
        soil_data: 'Soil Data',
        crop_growth_period: 'Crop Growth Period',
        sensor_parameter: 'Sensor Parameter',

        microclimate_analysis: 'Microclimate Analysis in Agriculture',
        agri_dashboard: 'Agri Dashboard',
        agriweather: 'AgriWeather',
        agri_weather_station: 'Agri Weather Station',
        system_info: 'System Info',
        field_construction_map: 'Field Construction Map',
        preview: 'Preview',
        coordinate: 'Coordinate',
        map: 'Map',
        map_get_current: 'Get Current Map Coordinate Settings',
        map_toolbox: 'Map Toolbox',
        map_coordinate: 'Map Center Coordinate',
        mouse_coordinate: 'Mouse Coordinate on Map',
        map_boundary: 'Map Boundary',
        longitude: 'Longtitude',
        latitude: 'Latitude',
        map_zoom: 'Map Zoom',
        online_map_boundary_editor: 'Online Map Boundary Editor',
        map_satellite: 'Satellite Map',
        map_street: 'Street Map',
        add_to_home_screen: 'Download AgriWeather APP Lite',
        get_geo_location: 'Get User Location (Coordinates)',
        max: 'Max',
        min: 'Min',
        alert_filter: 'Frequency',
        calculation_standard: 'Calculation Standard',
        no_alert_data: 'No Alert Data',
        alert_message: 'Alert Message',
        alert_rule: 'Alert Rule',
        alert_threshold: 'Alert Threshold',
        management_tool: 'Management Tool',
        previous: 'Previous',
        next: 'Next',
        little_doctor_agri: 'Little Doctor Agri',
        feedback_to_agri: 'Feedback to Agri',

        portal: 'Portal',
        is_refurbish: 'Refurbish',
        is_virtual: 'Virtual',
        is_rent: 'Rent',
        is_nbiot: 'NB-IoT',
        attention: 'Attention',
    },

    n: {
        all: 'All',
        other: 'Other',
        device: 'Device',
        weather_box: 'Weatherbox',
        sensor: 'Sensor',
        gateway: 'Gateway',
        weatherbox: 'Weatherbox',
        wand: 'Agri Sensor',
        camera: 'Time-lapse Camera',
        actuator: 'Actuator',
        extra: 'Extra Device',
        accesspoint: 'Access Point',

        temperature: 'Temperature',
        accumulatedTemperature: 'Accumulated Temperature',
        humidity: 'Humidity',
        rainfall: 'Rainfall',
        illuminance: 'Illuminance',
        atmospheric_pressure: 'Atmospheric Pressure',
        soil_temperature: 'Ssoil Temperature',
        soil_moisture: 'Soil Moisture',
        soil_conductivity: 'Soil Conductivity',
        battery: 'Battery',
        wind_speed: 'Wind Speed',
        wind_direction: 'Wind Direction',
        co2_concentration: 'CO2 Concentration',
        pH_value: 'pH value',

        cropping_season: 'Cropping Season',
        crop: 'Crop',
        variety: 'Variety',
        farm: 'Farm',
        field: 'Field',
        worker: 'Worker',

        weight: 'Weight',
        quality: 'Quality',

        plants: 'plants',
        photos: 'photos',

        admin: 'Admin',
        beehive: 'Beehive',
        project_owner: 'Project Owner',
        project_member: 'Project Member',
        researcher: 'Researcher',
        guest: 'Guest',
    },

    status: {

        user: {
            enable: 'Enable',
            disabled: 'Disabled',
            deleted: 'Deleted',
        },

        record_action: {
            manufacture: 'Manufacture',
            assemble: 'Assemble',
            fix: 'Fix',
            ready: 'Ready',
            reborn: 'Reborn',
            package: 'Package',
            ship: 'Ship',
            activate: 'Activate',
            report: 'Report',
            broken: 'Broken',
            takeback: 'Takeback',
            sendback: 'Sendback',
            recall: 'Recall',
            withdraw: 'Withdraw',
            pushqueue: 'Pushqueue',
            repair: 'Repair',
            hotfix: 'Hotfix',
            pend: 'Pend',
            terminate: 'Terminate',

            warn: 'Warn',
            upgrade: 'Upgrade ',
            record: 'Record',
            functest: 'Functest',
            other: 'Other',
        },

        device_status: {
            planning: 'Planning',
            wait4test: 'Wait 4 test',
            inventory: 'Inventory',
            tosend: 'To Send',
            shipping: 'Shipping',
            active: 'Active',
            investigating: 'Investigating',
            malfunction: 'Malfunction',
            waitcontact: 'Wait Contact',
            waitback: 'Wait Back',
            checking: 'Checking',
            inqueue: 'Inqueue',
            repairing: 'Repairing',
            resting: 'Resting',
            abandon: 'Abandon',
        },

    },

    report: {
        type: {
            monthly: 'Monthly',
            period:  'Period',
        },
        ui: {
            overview:       'Overview',
            air:            'Air',
            sun:            'Sun',
            water:          'Water',
            comment:        'Comment',
            alert_calendar: 'Alerts Calendar',
            daily:          'Daily',
            chart:          'Chart',
            kchart:         'Candlestick Chart',
        },
    },

    u: {
        empty: 'undefined unit',

        unit: 'Unit',
        date: 'Date',
        period: 'Period',
        years: 'years',
        years_ago: 'years ago',
        months: 'months',
        months_ago: 'months ago',
        weeks: 'weeks',
        weeks_ago: 'weeks ago',
        day: 'day',
        days: 'days',
        days_ago: 'days ago',
        hours: 'hours',
        hours_ago: 'hours ago',
        minutes: 'minutes',
        minutes_ago: 'minutes ago',
        seconds: 'seconds',
        seconds_ago: 'seconds ago',
        time: 'Time',
        time_s: 'Time(s)',

        per_hour: 'per hour',
        per_day: 'per day',
        per_month: 'per month',
        per_year: 'per year',
        past_years: 'Years',

        m: 'meter',
        km: 'kilometer',
        cm: 'centimeter',
        mm: 'milimeter',
        taiwanese_foot: 'Taiwanese foot (0.3 meter)',
        feet: 'feet',

        kg: 'kilogram',

        m2: 'm<sup>2</sup>',
        ha: 'ha',
        hun: '分(hun)',
        kah: '甲(kah)',
        acre: 'acre',

        pictures: 'pictures',

        // sensor unit
        temperature_c: '℃',
        humidity: '%',
        moisture: '%',
        percentage: '%',
        ppm: 'ppm',
        air_pm: 'μg/m<sup>3</sup>', // 空氣細懸浮微粒濃度 μg/m<sup>3</sup>
        pressure: 'hPa',
        speed_wind: 'm/s',
        degree: 'degree',
        rate_rain: 'mm/hr',
        mm: 'mm',
        radiation: 'W/m<sup>2</sup>',
        lux: 'lux',
        par: 'μmol/m<sup>2</sup>s',
        uvi: 'UVI',
        conductivity: 'ds/m',
        ph: '',
        voltage: 'V',
        dbm: 'dBm',
        db: 'dB',
    },

    sensor: {
        default: '',                            u_default: '',

        air_temperature: 'Temperature',         u_air_temperature: '℃',
        air_humidity: 'Humidity',               u_air_humidity: '%',
        air_temperature02: 'Temperature 02',    u_air_temperature02: '℃',
        air_humidity02: 'Humidity 02',          u_air_humidity02: '%',

        mixture_temperature: 'Mixture Temperature',  u_mixture_temperature: '℃',

        air_co2_temperature: 'CO2 Temperature', u_air_co2_temperature: '℃',
        air_co2_humidity: 'CO2 Humidity',       u_air_co2_humidity: '%',

        air_co: 'CO Concentration',             u_air_co: 'ppm',
        air_co2: 'CO2 Concentration',           u_air_co2: 'ppm',
        air_nh3: 'NH3 Concentration',           u_air_nh3: 'ppm',
        air_h2s: 'H2S Concentration',           u_air_h2s: 'ppm',

        air_pm25: 'PM 2.5',                        u_air_pm25: 'μg/m<sup>3</sup>', // 空氣細懸浮微粒濃度
        air_pm10: 'PM 10',                         u_air_pm10: 'μg/m<sup>3</sup>', // 空氣細懸浮微粒濃度

        air_pressure: 'Atmospheric Pressure',   u_air_pressure: 'hPa',
        wind_speed: 'Wind Speed',               u_wind_speed: 'm/s',
        wind_direction: 'Wind Direction',       u_wind_direction: 'degree',
        rain_rate: 'Rainfall Rate',             u_rain_rate: 'mm/hr',
        rain_rate_raw: 'Rainfall Rate (Raw Data)', u_rain_rate_raw: 'mm/hr',
        rainfalls: 'Rainfalls (24 hour)',       u_rainfalls: 'mm',

        water_depth: 'Water Depth',             u_water_depth: 'mm',

        leaf_temperature: 'Leaf Temperature',   u_leaf_temperature: '℃',
        leaf_humidity: 'Leaf Humidity',         u_leaf_humidity: '%',

        dew_point: 'Dew Point',                 u_dew_point: '℃',
        solar_radiation: 'Light Intensity',     u_solar_radiation: 'W/m<sup>2</sup>',
        solar_lux: 'Illuminance',               u_solar_lux: 'lux',
        solar_par: 'PAR',                       u_solar_par: 'μmol/m<sup>2</sup>s',
        uvi: 'Ultra-Violet Index',              u_uvi: 'UVI',

        soil_temperature: 'Soil Temperature',   u_soil_temperature: '℃',
        soil_moisture: 'Soil Moisture',         u_soil_moisture: '%',
        soil_conductivity: 'Soil EC',           u_soil_conductivity: 'ds/m',
        soil_conductivity_pore: 'Soil EC-PORE', u_soil_conductivity_pore: 'ds/m',
        soil_ph: 'pH Value',                    u_soil_ph: '',

        voltage: 'Voltage',                     u_voltage: 'V',
        solar_voltage: 'Solar Voltage',         u_solar_voltage: 'V',
        bug_num: 'Number of Insects Captured',  u_bug_num: '',
        daily_bugs: 'Daily Captured Insects',   u_daily_bugs: '',
        battery_percentage: 'Battery',          u_battery_percentage: '%',
        voltage_to_battery_percentage: 'Battery', u_voltage_to_battery_percentage: '%',
        inner_temperature: 'Inner Temperature', u_inner_temperature: '℃',
        rssi: 'Signal Strength',                u_rssi: 'dBm',
        snr: 'Signal-to-noise Ratio',           u_snr: 'dB',
        rssi_to_signal_bar: 'Signal Strength',  u_rssi_to_signal_bar: '',
        lqi: 'Linkquality',                     u_lqi: '',
        lqi_to_signal_bar: 'Linkquality',       u_lqi_to_signal_bar: '',

        // WA
        rainfalls_1h: 'Rainfalls (1 hour)',     u_rainfalls_1h: 'mm',
        rainfalls_3h: 'Rainfalls (3 hours)',    u_rainfalls_3h: 'mm',

        /**
         * report
         * */
        // 舊版(2019.12以前) report keyword
        air_temperature_max: 'Temperature Max',     u_air_temperature_max: '℃',
        air_temperature_min: 'Temperature Min',     u_air_temperature_min: '℃',
        air_temperature_mean: 'Temperature Avg.',   u_air_temperature_mean: '℃',
        solar_hour: 'Sunshine Hours',               u_solar_hour: 'hours',
        solar_mj: 'Accumulated Sunshine',           u_solar_mj: 'MJ',

        // report - air:
        // 氣溫圖表：
        daily_mean_temp:    'Mean Temp.',       u_daily_mean_temp:  '℃', // '(Daily) Mean Temp.',
        daily_high_temp:    'High Temp.',       u_daily_high_temp:  '℃', // '(Daily) High Temp.',
        daily_low_temp:     'Low Temp.',        u_daily_low_temp:   '℃', // '(Daily) Low Temp.',
        // 氣溫統計值：
        avg_daily_high_temp:  'Avg. High Temp.',         u_avg_daily_high_temp:  '℃',
        avg_daily_temp:       'Avg. Temp.',              u_avg_daily_temp:       '℃',
        avg_daily_low_temp:   'Avg. Low Temp.',          u_avg_daily_low_temp:   '℃',
        avg_daily_diff_temp:  'Avg. Temp. Difference',   u_avg_daily_diff_temp:  '℃',
        // 濕度圖表：
        daily_mean_humidity:    'Mean Humidity',   u_daily_mean_humidity:    '%', // '(Daily) Mean Humidity',
        daily_high_humidity:    'High Humidity',   u_daily_high_humidity:    '%', // '(Daily) High Humidity',
        daily_low_humidity:     'Low Humidity',    u_daily_low_humidity:     '%', // '(Daily) Low Humidity',
        // 濕度統計值：
        avg_daily_high_humidity:  'Avg. High Humidity',          u_avg_daily_high_humidity:  '%',
        avg_daily_humidity:       'Avg. Humidity',               u_avg_daily_humidity:       '%',
        avg_daily_low_humidity:   'Avg. Low Humidity',           u_avg_daily_low_humidity:   '%',
        avg_daily_diff_humidity:  'Avg. Humidity Difference',    u_avg_daily_diff_humidity:  '%',

        // report - sun:
        // 日照圖表：
        daily_sun_hours:        'Sunshine Hours',       u_daily_sun_hours: 'hours',     // '(Daily) Sun Hours',
        daily_solar_radiation:  'Solar Radiation',      u_daily_solar_radiation:  'MJ', // '(Daily) Solar Radiation',
        // 日照統計值：
        acc_daily_sun_hours:          'Accumulated Sunshine Hours',    u_acc_daily_sun_hours:          'hours',
        acc_daily_solar_radiation:    'Accumulated Solar Radiation',   u_acc_daily_solar_radiation:    'MJ',
        avg_daily_sun_hours:          'Avg. Sunshine Hours',           u_avg_daily_sun_hours:          'hours',
        avg_daily_solar_radiation:    'Avg. Solar Radiation',          u_avg_daily_solar_radiation:    'MJ',

        // report - water:
        // 降雨圖表：
        daily_precipitation:    'Precipitation',            u_daily_precipitation:    'mm', // '(Daily) Precipitation',
        // 降雨統計值：
        acc_rainfall:           'Accumulated Rainfall',     u_acc_rainfall:           'mm',
        rainy_days:             'Rainy Days',               u_rainy_days:             'days',
        days_of_heavy_rain:     'Days of Heavy Rain',       u_days_of_heavy_rain:     'days',

        // report - sensors:
        // 土溫圖表：
        daily_mean_soil_temp:    'Mean Soil Temp.',       u_daily_mean_soil_temp:  '℃',
        daily_high_soil_temp:    'High Soil Temp.',       u_daily_high_soil_temp:  '℃',
        daily_low_soil_temp:     'Low Soil Temp.',       u_daily_low_soil_temp:   '℃',
        // 土溫統計值：(土溫與氣溫計算規則相似，故 key 以相似規則命名)
        avg_daily_high_soil_temp:  'Average High Soil Temp.',       u_avg_daily_high_soil_temp:  '℃',
        avg_daily_soil_temp:       'Average Soil Temp.',            u_avg_daily_soil_temp:       '℃',
        avg_daily_low_soil_temp:   'Average Low Soil Temp.',        u_avg_daily_low_soil_temp:   '℃',
        avg_daily_diff_soil_temp:  'Average Soil Temp. Difference', u_avg_daily_diff_soil_temp:  '℃',
        // 土濕圖表(K 線)
        daily_start_soil_moisture:  'Start Soil Moisture',  u_daily_start_soil_moisture: '%',
        daily_high_soil_moisture:   'High Soil Moisture',   u_daily_high_soil_moisture: '%',
        daily_low_soil_moisture:    'Low Soil Moisture',    u_daily_low_soil_moisture: '%',
        daily_end_soil_moisture:    'End Soil Moisture',    u_daily_end_soil_moisture: '%',
        // 土濕統計值：
        avg_daily_soil_moisture:        'Average Soil Moisture',   u_avg_daily_soil_moisture:       '%',
        avg_daily_high_soil_moisture:   'Max Soil Moisture',        u_avg_daily_high_soil_moisture: '%',
        avg_daily_low_soil_moisture:    'Min Soil Moisture',        u_avg_daily_low_soil_moisture:  '%',
        // 土EC圖表(K 線)
        daily_start_soil_conductivity:  'Start Soil EC',    u_daily_start_soil_conductivity: 'ds/m',
        daily_high_soil_conductivity:   'High Soil EC',     u_daily_high_soil_conductivity: 'ds/m',
        daily_low_soil_conductivity:    'Low Soil EC',      u_daily_low_soil_conductivity: 'ds/m',
        daily_end_soil_conductivity:    'End Soil EC',      u_daily_end_soil_conductivity: 'ds/m',
        // 土EC統計值：
        avg_daily_soil_conductivity:        'Average Soil EC',   u_avg_daily_soil_conductivity:     'ds/m',
        avg_daily_high_soil_conductivity:   'Max Soil EC',      u_avg_daily_high_soil_conductivity: 'ds/m',
        avg_daily_low_soil_conductivity:    'Min Soil EC',      u_avg_daily_low_soil_conductivity:  'ds/m',
        // 殘差值
        soil_conductivity_residual: 'Residual', u_soil_conductivity_residual: 'ds/m',
        // 土 pH 圖表(K 線)
        daily_start_soil_ph:  'Start Soil pH',  u_daily_start_soil_ph: '',
        daily_high_soil_ph:   'High Soil pH',   u_daily_high_soil_ph: '',
        daily_low_soil_ph:    'Low Soil pH',    u_daily_low_soil_ph: '',
        daily_end_soil_ph:    'End Soil pH',    u_daily_end_soil_ph: '',
        // 土 pH 統計值：
        avg_daily_soil_ph:        'Average Soil pH',    u_avg_daily_soil_ph:      '',
        avg_daily_high_soil_ph:   'Max Soil pH',        u_avg_daily_high_soil_ph: '',
        avg_daily_low_soil_ph:    'Min Soil pH',        u_avg_daily_low_soil_ph:  '',
    },

    chart: {
        no_rain: 'No Rain',
        drizzle: 'Drizzle',
        rain: 'Rain',
        sprinkle: 'Sprinkle',
        moderate_rain: 'Moderate Rain',
        heavy_rain: 'Heavy Rain',
        extremely_heavy_rain: 'Extremely Heavy Rain',
        torrential_rain: 'Torrential Rain',
        extremely_torrential_rain: 'Extremely Torrential Rain',

        low_impact: 'Low Impact',
        medium_impact: 'Medium Impact',
        high_impact: 'High Impact',

        wind_strong: 'Strong',
        wind_moderate: 'Moderate',
        wind_light: 'Light',
        wind_spary_interference: 'Spary Interference',

        // rssi & snr
        signal_strong: 'Strong',
        signal_good: 'Good',
        signal_ok: 'Normal',
        signal_lowest_limit: 'Lowest Limit',

        air_pm_unhealthy: 'Unhealthy',
    },

    stress: {
        0: 'Normal',
        1: 'Suitable conditions',
        2: 'Abnormal',
        3: 'High-temperature injury',
        4: 'Low-temperature injury',
        5: 'High irradiance',
        6: 'Low irradiance',
        7: 'Wind damage',
        8: 'High soil temperature',
        9: 'Low soil temperature',
        10: 'Water excess',
        11: 'Water deficit',
        12: 'High soil EC',
        13: 'Low soil EC',
        14: 'High soil pH',
        15: 'Low soil pH',

        G001: 'Normal',
        G002: 'Abnormal',

        A001: 'Suitable conditions',
        A002: 'Attention! High Temperature!',
        A003: 'Attention! Low Temperature!',
        A004: 'Attention! High Solar Irradiance!',
        A005: 'Attention! Low Solar Irradiance!',
        A006: 'Attention! Strong Wind!',
        A007: 'Attention! High Soil Temperature!',
        A008: 'Attention! Low Soil Temperature!',
        A009: 'Attention! Water Excess!',
        A010: 'Attention! Water Deficit!',
        A011: 'Attention! High Soil EC !',
        A012: 'Attention! Low Soil EC !',
        A013: 'Attention! High Soil pH !',
        A014: 'Attention! Low Soil pH !',
        A015: 'Attention! Foehn!',

        S001: 'Suitable conditions',
        S002: 'High-temperature injury',
        S003: 'Low-temperature injury',
        S004: 'High irradiance',
        S005: 'Low irradiance',
        S006: 'Wind damage',
        S007: 'High soil temperature',
        S008: 'Low soil temperature',
        S009: 'Water excess',
        S010: 'Water deficit',
        S011: 'High soil EC',
        S012: 'Low soil EC',
        S013: 'High soil pH',
        S014: 'Low soil pH',
        S015: 'Foehn',

        T001: 'Official Typhoon Warning',
        T002: 'Official Weather Warning ',
    },

    date: {
        Mon: 'Mon',
        Tue: 'Tue',
        Wed: 'Wed',
        Thu: 'Thu',
        Fri: 'Fri',
        Sat: 'Sat',
        Sun: 'Sun',
        week: 'Week',

        months: {
            January: 'January',
            February: 'February',
            March: 'March',
            April: 'April',
            May: 'May',
            June: 'June',
            July: 'July',
            August: 'August',
            September: 'September',
            October: 'October',
            November: 'November',
            December: 'December',
        },

        solar_terms: 'Solar Terms',
        solar_term_list:
        {
            0: 'Moderate Cold',
            1: 'Severe Cold',
            2: 'Spring Commences',
            3: 'Spring Showers',
            4: 'Insects Waken',
            5: 'Vernal Equinox',
            6: 'Bright and Clean',
            7: 'Corn Rain',
            8: 'Summer Commences',
            9: 'Corn Forms',
            10: 'Corn on Ear',
            11: 'Summer Solstice',
            12: 'Moderate Heat',
            13: 'Great Heat',
            14: 'Autumn Commences',
            15: 'End of Heat',
            16: 'White Dew',
            17: 'Autumnal Equinox',
            18: 'Cold Dew',
            19: 'Frost',
            20: 'Winter Commences',
            21: 'Light Snow',
            22: 'Heavy Snow',
            23: 'Winter Solstice',
        }
    },

    wind: {
        W: 'W',
        WSW: 'WSW',
        SW: 'SW',
        SSW: 'SSW',
        S: 'S',
        SSE: 'SSE',
        SE: 'SE',
        ESE: 'ESE',
        E: 'E',
        ENE: 'ENE',
        NE: 'NE',
        NNE: 'NNE',
        N: 'N',
        NNW: 'NNW',
        NW: 'NW',
        WNW: 'WNW',
        0: '',
    },

    weather: {
        0: 'Tornado',
        1: 'Tropical storm',
        2: 'Hurricane',
        3: 'Severe thunderstorms',
        4: 'Thunderstorms',
        5: 'Mixed rain and snow',
        6: 'Mixed rain and sleet',
        7: 'Mixed snow and sleet',
        8: 'Freezing drizzle',
        9: 'Drizzle',
        10: 'Freezing rain',
        11: 'Showers',
        12: 'Showers',
        13: 'Snow flurries',
        14: 'Light snow showers',
        15: 'Blowing snow',
        16: 'Snow',
        17: 'Hail',
        18: 'Sleet',
        19: 'Dust',
        20: 'Foggy',
        21: 'Haze',
        22: 'Smoky',
        23: 'Blustery',
        24: 'Windy',
        25: 'Cold',
        26: 'Cloudy',
        27: 'Mostly cloudy (night)',
        28: 'Mostly cloudy (day)',
        29: 'Partly cloudy (night)',
        30: 'Partly cloudy (day)',
        31: 'Clear (night)',
        32: 'Sunny',
        33: 'Fair (night)',
        34: 'Fair (day)',
        35: 'Mixed rain and hail',
        36: 'Hot',
        37: 'Isolated thunderstorms',
        38: 'Scattered thunderstorms',
        39: 'Scattered thunderstorms',
        40: 'Scattered showers',
        41: 'Heavy snow',
        42: 'Scattered snow showers',
        43: 'Heavy snow',
        44: 'Partly cloudy',
        45: 'Thundershowers',
        46: 'Snow showers',
        47: 'Isolated thundershowers',
        3200: 'Not available',
    },

    cgp: {
        crown_planting: 'Crown Planting',
        foliage_and_root_growth: 'Foliage and Root Growth',
        forcing: 'Forcing',
        flowering: 'Flowering',
        fruiting: 'Fruiting',
        harvesting: 'Harvesting',

        seedling: 'Seedling',
        leaf_development: 'Leaf Development',
        stem_elongation: 'Stem Elongation',
        tasseling_stage: 'Tasseling Stage',
        silking_stage: 'Silking Stage',
        milk_stage: 'Milk Stage',

        vegetative_stage: 'Vegetative Stage',
        bbch_7_soybean: 'Development of fruit',
        bbch_8_soybean: 'Ripening of fruit and seed',
    },

    err: {

    },

    tooltip: {
        data_set_align_bounding_left:   'Data sets align left y axis',
        data_set_align_bounding_right:  'Data sets align right y axis',
        show_data_toggle:               'Data show/hide toggle, to hide the data set temporarily.',
        zoom_data_slider:               'Zoom this data timeline, default shows full time range, drag the bounding of the slider to adjust timeline range.',
        min_value_of_the_first_data:    'The minimum value of the first data set.',
        max_value_of_the_first_data:    'The maximum value of the first data set.',
        hover_xaxis_show_tooltip:       'Hover to the X Axis on the chart, shows all the data and unit on the time.',
        yaxis_bounding_of_the_first_data: 'The left Y Axis is the data bounding of the first data set ( and the other data sets with the same unit ).',
        yaxis_bounding_of_all_data:     'The right Y Axis is the data bounding of all data set.',
    },

    other: {
        toastr_welcome: 'Welcome to AgriWeather !',
        toastr_hi: 'Hi, ',

        total_suffered: 'Total Suffered',
        join_agriweather: 'Join AgriWeather',
        register_now: 'Register Now',
        log_in_to_your_account_by_email: 'Log in to your account by email',
        enter_the_email_address_of_your_account: 'Enter the email address of your account',
        enter_the_password: 'Enter the old password',
        enter_the_new_password: 'Enter the new password',
        enter_the_new_password_again: 'Enter the new password again',
        enter_the_password_again: 'Enter the password again',
        send_reset_link_to_the_email: 'Send Reset Link to the Email',
        create_account: 'Create Account',
        have_an_agri_account: 'Have an agri account ?',
        go_to_login_page: 'Go to login page !',
        input_required: 'Fields marked with an asterisk * are required',
        phone_placeholder: 'EX: +886-912-345678',
        company_placeholder: 'Ex: Consultant / XXX Farm',
        format_supported: 'Format Supported',
        location_description: 'Simple Location Description, ex: US Calif',

        password_info_old: 'Please enter the old password',
        password_info_old_compare: 'The new password should not be the same as the old password',
        password_info_format: '6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter',
        password_info_new_compare: 'The new passwords are not the same',

        the_password_reset_email_has_been_sent_to_your_email: 'The password reset email has been sent to your email',
        email_address_not_found: 'Email Address Not Found',
        wrong_login: 'wrong email or password',
        disabled_login: 'Your account has been disabled or deleted.',
        set_password_failed: 'Reset Password Failed',
        set_password_succeeded: 'Reset Password Succeeded',
        register_failed: 'Register Failed',
        register_succeeded: 'Register Succeeded',
        update_failed: 'Update Failed',
        update_succeeded: 'Update Succeeded',
        coming_soon: 'Coming soon',
        under_maintance: 'This area is under maintance',
        under_developement: 'This area is under developement',
        confirm_delete: 'Are you sure you want to delete ?',
        confirm_delete_cropperiod: 'Are you sure you want to delete this cropping season ? It will be invisible & not editable after deleted.',
        confirm_done_cropperiod: 'Are you sure you want to set this cropping season done ? It will be moved to the list of finished cropping seasons & not editable.',
        confirm_done_cropperiod_2: 'After setting the End Date, please visit 〈Project Summary〉 to find environmental data or insights of cultivation.',
        conform_create_cropperiod: 'Crop, Crop Variety, and Planting Day are NOT editable. Please make sure the information are correct and we can provide you correct environmental standards for your crop.',
        conform_info: 'Please make sure the information are correct.',
        confirm_this_update: 'Confirm this Update ?',
        enter_confirmation_code: 'Enter Confirmation Code',
        show_charts: 'Show Charts',
        export_raw_data_to_CSV_file: 'Export Raw Data to CSV File',
        unexpected_error: 'Unexpected Error',
        please_try_it_later: 'Please try it later',
        data_not_found: 'The requested resource could not be found',
        the_link_has_expired: 'The link has expired',
        loading: 'Loading ...',
        finished_to_history: 'is finished, please search / download the data of devices in 【 History Page 】.',
        no_cropping_season_in_progress: 'No cropping season in progress.',
        copied_to_clipboard: 'Copied to clipboard !',
        copied_to_clipboard_failed: 'Copied to clipboard Failed !',
        login_to_comment: 'Login to Comment',

        upload_image_max_size: 'The maximum size of uploaded image is 2MB !!',
        map_filter_warning: 'The devices on the map is filtered by the filter tools ! Reset filter tools to see all devices of this project.',
        open_filter_tools: 'Open Filter Tools',

        line_help: 'Line Help',
        line_notify_not_showing: 'Not receiving notifications',
        plz_check_add_line_friend: 'Make sure you add an official account ( @linenotify ) as a friend.',
        plz_check_add_line_to_group: 'Make sure that the service ( the official account @linenotify ) has been properly linked, and that the place where you receive notifications has been selected correctly.',
        project_line_binded_count: 'How many Line Notify service can link to a project ?',
        a_project_a_line_group: 'Only one service. When the project linked with another Line Notify service, the system will auto unlink the original service.',
        line_contact: 'What the Line Notify service contains ?',
        detected_stress_alerts: 'The detected stress alerts.',
        detected_device_alerts: 'The detected device warning information.',
        public_warning: 'Public warning information.',
        service_binding_info: 'Information about the Line Notify service.',
        how_to_unlink_this_service: 'How to unlinked this Line Notify service ?',
        plz_press_the_button_to_unlink: 'Press this button to unlink the Line Notify service',
        browser_does_not_support: 'Your browser does not support the service.',
        plz_input_alert_message: 'Please input the alert message.',
        alert_custom_rule_info: 'The alerts of Email and Line Notify is according to agriweather rules and these custom rules, but the real-time dashboard is only according to agriweather rules.',
    },
}
