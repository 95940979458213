import DateTools from '@/tools/date';
import * as CustomerAPI from '@/apis/customer';
import * as CustomerRecordAPI from '@/apis/customer_record';

// initial state
const state = {

  customer_detail: undefined,

  list_customer_record_items: [
    { text: '無項目', value: 'no_item' },
    { text: '18650 電池充電器', value: '18650_charger' },
    { text: '額外 18650 電池', value: 'added_18650' },
    { text: '額外電池包', value: 'added_battery_pack' },
    { text: '電池包充電線', value: 'battery_pack_charging_cable' },
    { text: '電池包充電變壓器', value: 'battery_pack_transformer' },
    { text: '4G router', value: '4g_router' },
    { text: '4G dongle', value: '4g_dongle' },
    { text: 'UPS', value: 'ups' },
    { text: '大天線', value: 'antenna_big' },
    { text: '防水箱', value: 'watertight_case' },
    { text: '氣象站腳架', value: 'weatherbox_tripod' },
    { text: '待新增項目', value: 'other' },
  ],

  list_sort: [
    { value: '-customer_id', text: '客戶 id 降序' },
    { value: 'customer_id', text: '客戶 id' },

    { value: '-start_date', text: '開始日期 降序' },
    { value: 'start_date', text: '開始日期' },

    { value: '-updated_at', text: '更新時間 降序' },
    { value: 'updated_at', text: '更新時間' },

    { value: '-created_at', text: '建立時間 降序' },
    { value: 'created_at', text: '建立時間' },
  ],

};

// getters
const getters = {

  map_ItemCode_TO_ItemName: (state) => (code) => state.list_customer_record_items.find((item) => item.value === code),
};

// actions
const actions = {

  get_Record_Data(context, settings) {
    return new Promise((resolve, reject) => {
      CustomerRecordAPI.api_Get_Record(settings.params)
        .then((res) => {
          const resData = res.data;

          if (resData) {
            if (resData.customer_record) {
              const _timezone = this.state.user.timezone; // 取得其他 namespace 的 state

              resData.customer_record.record_time = DateTools.dateTimeZone(resData.customer_record.record_time, _timezone, 'YYYY-MM-DD HH:mm');
              resData.customer_record.updated_at = DateTools.dateTimeZone(resData.customer_record.updated_at, _timezone, 'YYYY-MM-DD HH:mm');
            }
          }

          resolve(resData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  get_Records(context, settings) {
    return new Promise((resolve, reject) => {
      CustomerRecordAPI.api_Get_Records(settings.query)
        .then((res) => {
          const resData = res.data;

          if (resData) {
            if (resData.customer_records) {
              const _timezone = this.state.user.timezone; // 取得其他 namespace 的 state

              resData.customer_records.forEach((_record) => {
                _record.record_time = DateTools.dateTimeZone(_record.record_time, _timezone, 'YYYY-MM-DD HH:mm');
                _record.updated_at = DateTools.dateTimeZone(_record.updated_at, _timezone, 'YYYY-MM-DD HH:mm');

                _record.item_name = context.getters.map_ItemCode_TO_ItemName(_record.item).text;
              });
            }
          }
          resolve(resData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  get_Customer_Records(context, settings) {
    return new Promise((resolve, reject) => {
      CustomerAPI.api_Get_Customer_Records(settings.params, settings.query)
        .then((res) => {
          const resData = res.data;

          if (resData) {
            if (resData.customer_records) {
              const _timezone = this.state.user.timezone; // 取得其他 namespace 的 state

              resData.customer_records.forEach((_record) => {
                _record.record_time = DateTools.dateTimeZone(_record.record_time, _timezone, 'YYYY-MM-DD HH:mm');
                _record.updated_at = DateTools.dateTimeZone(_record.updated_at, _timezone, 'YYYY-MM-DD HH:mm');

                _record.item_name = context.getters.map_ItemCode_TO_ItemName(_record.item).text;
              });
            }
          }
          resolve(resData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  get_Customer_Detail(context, settings) {
    return new Promise((resolve, reject) => {
      CustomerAPI.api_Get_Customer_Detail(settings.params)
        .then((res) => {
          const resData = res.data;

          if (resData) {
            if (resData.customer) {
              const _timezone = this.state.user.timezone; // 取得其他 namespace 的 state

              if (resData.customer.start_date) resData.customer.start_date = DateTools.dateTimeZone(resData.customer.start_date, _timezone, 'YYYY-MM-DD');
              resData.customer.updated_at = DateTools.dateTimeZone(resData.customer.updated_at, _timezone, 'YYYY-MM-DD HH:mm');
              resData.customer.created_at = DateTools.dateTimeZone(resData.customer.created_at, _timezone, 'YYYY-MM-DD HH:mm');

              context.commit('set_Customer', resData.customer);
            }
          }

          resolve(resData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  get_Customers(context, settings) {
    return new Promise((resolve, reject) => {
      CustomerAPI.api_Get_Customers(settings.query)
        .then((res) => {
          const resData = res.data;

          if (resData) {
            if (resData.customers) {
              const _timezone = this.state.user.timezone; // 取得其他 namespace 的 state

              resData.customers.forEach((_customer) => {
                if (_customer.start_date) _customer.start_date = DateTools.dateTimeZone(_customer.start_date, _timezone, 'YYYY-MM-DD');
                _customer.updated_at = DateTools.dateTimeZone(_customer.updated_at, _timezone, 'YYYY-MM-DD');
                _customer.created_at = DateTools.dateTimeZone(_customer.created_at, _timezone, 'YYYY-MM-DD');
              });
            }
          }

          resolve(resData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

};

// mutations
const mutations = {

  set_Customer(state, data) {
    state.customer_detail = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
