export default {

  getWindowSize() {
    return {
      width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
      height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
    };
  },

  // 滑鼠捲動到某個 DOM 元件所在地
  scrollToDOM(target, browser = undefined) {
    // if ( browser !== 'IE' )
    // {
    target.scrollIntoView({
      block: 'start', // 垂直方向對齊：start, end, center, nearest
      inline: 'nearest', // 水平方向對齊：start, end, center, nearest
      behavior: 'smooth', // 動畫過度效果：auto, smooth
    });
    // }
    // else
    // {
    //     target.scrollIntoView(true)
    // }
  },
  use_scrollBy(offsetX = 0, offsetY = 0) {
    window.scrollBy({
      left: offsetX,
      top: offsetY,
      behavior: 'smooth',
    });
  },
  // 滑鼠捲動到指定 ID 的所在地：先找到該 DOM 元件 => 捲動過去 => 再進行上下左右位移
  goToID(divID, browser = undefined, offsetX = 0, offsetY = -70) {
    if (divID !== undefined) {
      // 使用 id 取得 DOM 元件，再捲動畫面到該元件的位置
      const _hash_free_ID = divID.replace('#', ''); // 將 # 字去掉
      const _target = document.getElementById(_hash_free_ID);

      this.scrollToDOM(_target, browser);

      // 延遲呼叫 window.scrollBy 來移動 offset，不然會跟 scrollIntoView 打架導致不會捲動
      const _waiting_second = 0.8;
      setTimeout(() => {
        _target.scrollIntoView(true);
        this.use_scrollBy(offsetX, offsetY);
      }, _waiting_second * 1000);
    }
  },

  page_GoTO(router, steps = -1) {
    router.go(steps);
  },

  goToPage(router, name, params = {}, query = {}, openNewTab = false) {
    if (openNewTab) {
      const routeData = router.resolve({ name, params, query });
      window.open(routeData.href, '_blank');
    } else {
      router.push({ name, params, query });
    }
  },

  openNewPage(url) {
    window.open(url, '_blank');
  },

  shortenText(text = undefined, length = undefined) {
    if (text) {
      let _shorten_length = (length !== undefined ? parseInt(length) : 100);
      switch (length) {
        case 'description': _shorten_length = 100; break;
        case 'context': _shorten_length = 200; break;
        case 'name': _shorten_length = 50; break;
        default: break;
      }
      let _new_text = text.toString();
      _new_text = _new_text.slice(0, _shorten_length);

      if (text.length > _shorten_length) _new_text += ' ...';

      return _new_text;
    }
    return text;
  },

  updateSideBar(Vue) {
    Vue.$store.dispatch('project/getProjects')
      .then((res) => {})
      .catch((err) => {});
  },

  /**
     * sortArrayObject 根據 key 來排序 array object
     */
  sortArrayObject(arrayObj, key) {
    const _new_array = arrayObj.slice();

    function _compare(a, b) {
      if (a[key] < b[key]) { return -1; }
      if (a[key] > b[key]) { return 1; }
      return 0;
    }

    return _new_array.sort(_compare);
  },

  /**
     * reorderDevice 對裝置做排序
     * @param {array} deviceList - { 感測器資料物件 } 組成的清單
     */
  reorderDevice(deviceList) {
    if (deviceList) {
      const _list_camera = []; // 縮時攝影
      const _list_WB = []; // 實體氣象站，EX Davis
      const _list_weatherbox = []; // 虛擬氣象站
      const _list_wand = []; // 阿龜魔杖
      const _list_extra = []; // 外部裝置，EX 小米溫濕度
      const _newDevices = []; // 重新排序後的 array

      deviceList.forEach((device) => {
        if (device.type === 'camera') {
          _list_camera.push(device);
        } else if (device.type === 'weatherbox') {
          if (device.hashid[1] !== 'A') {
            // 對所有感測器參數做排序
            device.sensor_params = this.reorderDeviceParams(device.sensor_params, device.type);
            // 對要顯示的感測器參數排序
            device.visualization = this.reorderDeviceParams(device.visualization, device.type);
            _list_WB.push(device);
          } else _list_weatherbox.push(device);
        } else if (device.type === 'wand') {
          _list_wand.push(device);
        } else if (device.type === 'extra') {
          _list_extra.push(device);
        }
      });

      _list_camera.forEach((device) => { _newDevices.push(device); });
      _list_WB.forEach((device) => { _newDevices.push(device); });
      _list_weatherbox.forEach((device) => { _newDevices.push(device); });
      _list_wand.forEach((device) => { _newDevices.push(device); });
      _list_extra.forEach((device) => { _newDevices.push(device); });

      return this.sortArrayObject(_newDevices, 'nickname'); // 根據 nickname 排序
      // return _newDevices
    }
    return [];
  },
  /**
     * reorderDeviceParams 對裝置感測器偵測項目做排序
     * @param {array}  deviceParams - 感測器偵測項目 組成的清單
     * @param {string} deviceType   - 感測器的種類分類
     */
  reorderDeviceParams(deviceParams, deviceType) {
    let _order_1;
    let _order_2;
    let _order_3;
    let _order_4;
    let _order_5;
    const _order_middle = [];
    const _order_last = [];
    const _order_new = [];

    if (deviceParams) {
      if (deviceType === 'weatherbox') {
        deviceParams.forEach((_param) => {
          switch (_param) {
            // 排序
            case 'air_temperature': _order_1 = _param; break;
            case 'air_temperature02': _order_1 = _param; break;
            case 'solar_radiation': _order_2 = _param; break;
            case 'solar_lux': _order_2 = _param; break;
            case 'solar_par': _order_2 = _param; break;

            case 'air_humidity': _order_3 = _param; break;
            case 'air_humidity02': _order_3 = _param; break;

            case 'air_pressure': _order_4 = _param; break;

              // 最後
            case 'wind_speed': _order_last.push(_param); break;
            case 'wind_direction': _order_last.push(_param); break;

              // 中間
            default: _order_middle.push(_param); break;
          }
        });
      } else if (deviceType === 'wand') {
        deviceParams.forEach((_param) => {
          switch (_param) {
            // 最後
            case 'rssi': _order_last.push(_param); break;
            case 'snr': _order_last.push(_param); break;
            case 'voltage': _order_last.push(_param); break;
            case 'battery_percentage': _order_last.push(_param); break;

              // 中間
            default: _order_middle.push(_param); break;
          }
        });
      } else if (deviceType === 'extra') {
        deviceParams.forEach((_param) => {
          switch (_param) {
            // 最後
            case 'battery_percentage': _order_last.push(_param); break;

              // 中間
            default: _order_middle.push(_param); break;
          }
        });
      }

      if (_order_1) _order_new.push(_order_1);
      if (_order_2) _order_new.push(_order_2);
      if (_order_3) _order_new.push(_order_3);
      if (_order_4) _order_new.push(_order_4);
      if (_order_5) _order_new.push(_order_5);

      _order_middle.forEach((_param) => {
        _order_new.push(_param);
      });
      _order_last.forEach((_param) => {
        _order_new.push(_param);
      });

      return _order_new;
    }
  },

  /**
     * getPercentage [ 電壓 ] 轉換成 [ 百分比 ]
     */
  getPercentage(voltage) {
    if (voltage !== undefined) {
      if (voltage >= 4.2) return 100;
      if (voltage >= 4.1) return 90;
      if (voltage >= 4.0) return 80;
      if (voltage >= 3.9) return 60;
      if (voltage >= 3.8) return 40;
      if (voltage >= 3.7) return 15;
      if (voltage >= 3.6) return 6;
      if (voltage >= 3.5) return 2;
      return 0;
    }

    return null;
  },
  /**
     * getVoltageToPercentage 從 [ 電壓 ] 轉換成 [ 電量%數 ]
     * @param {array} voltageData - 電壓數值清單
     */
  getVoltageToPercentage(voltageData) {
    if (voltageData !== undefined) {
      const _result = [];
      const _this = this;

      voltageData.forEach((voltage) => {
        _result.push(_this.getPercentage(voltage));
      });
      return _result;
    }
    console.log('warning: Voltage to Percentage Error');
    return voltageData;
  },

  /**
     * getSignalBar [ 訊號強度 ] 轉換成 [ 手機訊號格數 ]
     */
  getSignalBar(signal) {
    if (signal !== undefined) {
      if (signal >= -80) return 5;
      if (signal >= -100) return 4;
      if (signal >= -110) return 3;
      if (signal >= -120) return 2;
      return 1;
    }

    return null;
  },
  /**
     * getRssiToSignalBar 從 [ 訊號強度 ] 轉換成 [ 手機訊號格數 ]
     * @param {array} signalData - 訊號強度數值清單
     */
  getRssiToSignalBar(signalData) {
    if (signalData !== undefined) {
      const _result = [];
      const _this = this;

      signalData.forEach((signal) => {
        _result.push(_this.getSignalBar(signal));
      });
      return _result;
    }
    console.log('warning: Signal Strength to Signal Strength Bar Error');
    return signalData;
  },

  /**
     * getSignalBar_LQI [ 訊號強度 ] 轉換成 [ 手機訊號格數 ]
     */
  getSignalBar_LQI(signal) {
    if (signal !== undefined) {
      if (signal >= 201) return 6;
      if (signal >= 156) return 5;
      if (signal >= 106) return 4;
      if (signal >= 61) return 3;
      if (signal >= 31) return 2;
      return 1;
    }

    return null;
  },
  /**
     * getLQIToSignalBar 從 [ 訊號品質指標 ] 轉換成 [ 訊號格數 ]
     * @param {array} lqiData - 訊號品質指標數值清單
     */
  getLQIToSignalBar(lqiData) {
    if (lqiData !== undefined) {
      const _result = [];
      const _this = this;

      lqiData.forEach((signal) => {
        _result.push(_this.getSignalBar_LQI(signal));
      });
      return _result;
    }
    console.log('warning: LQI to LQI Bar Error');
    return lqiData;
  },
};
