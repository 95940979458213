

import DateTools from '@/tools/date'
import * as GatewayAPI from '@/apis/gateway'

// initial state
const state = {

  column_display_by_type: {
    tbid:             { GA: true, GC: true, }, // ThingsBoard Id
    aeon_yid:         { GB: true, }, // 翼詠 Yid
    aeon_mac_address: { GB: true, }, // 翼詠 Mac Adress
  },

  list_attribute: [
    { value: 'is_refurbish' },
    { value: 'is_rent' },
    { value: 'attention' },
    { value: 'is_virtual' },
  ],

  list_hw_version: [
    { text: '--：其他，待新增選項', value: 0 },
    { text: 'V1：初代目 cisco 黑外殼', value: 1 },
    { text: 'V2：白（有 CP2102）', value: 2 },
    { text: 'V3：白（無 CP2102）', value: 3 },
    { text: 'V4：鐵殼（黑 or 藍）', value: 4 },
    { text: 'V5：EDA CM3', value: 5 },
    { text: 'V6：AVNet 安富利', value: 6 },
    { text: 'V7：EDA CM4 Sensing', value: 7 },
    { text: 'V8：戶外太陽能版本', value: 8 },
  ],

  list_gw_network: [
    { value: undefined, text: '未選擇' },
    { value: '4g_dongle', text: '4G Dongle', description: '使用 4G Dongle 行動網卡' },
    { value: '4g_router', text: '4G Router + Wired', description: '使用 4G Router 以有線方式接到閘道器' },
    { value: '4g_router_wifi', text: '4G Router + WIFI', description: '使用 4G Router 以無線方式接到閘道器' },
    { value: 'wifi', text: 'WIFI', description: '使用現場的無線網路' },
    { value: 'catm1', text: 'Cat M1', description: 'Cat.M1 LTE網路' },
    { value: 'no', text: '未附網路設備' },
  ],

}

// getters
const getters = {

}

// actions
const actions = {

  get_Gateway_Records(context, settings) {
    return new Promise((resolve, reject) => {

      GatewayAPI.api_Get_Gateway_Records(settings.params, settings.query)
        .then(res => {
          let resData = res.data

          if (resData) {
            if (resData.records) {
              let _timezone = this.state.user.timezone;  // 取得其他 namespace 的 state

              resData.records.forEach(_record => {
                _record.record_time = DateTools.dateTimeZone(_record.record_time, _timezone, 'YYYY-MM-DD HH:mm')
                _record.updated_at = DateTools.dateTimeZone(_record.updated_at, _timezone, 'YYYY-MM-DD HH:mm')
              })
            }
          }


          resolve(resData);
        })
        .catch(err => {
          reject(err);
        })
    })
  },

  get_Gateway_Detail(context, settings) {
    return new Promise((resolve, reject) => {

      GatewayAPI.api_Get_Gateway_Detail(settings.params)
        .then(res => {
          let resData = res.data

          if (resData) {
            if (resData.gateway) {
              let _timezone = this.state.user.timezone;  // 取得其他 namespace 的 state

              resData.gateway.category = 'gateway'
              resData.gateway.manufacture_date = DateTools.dateTimeZone(resData.gateway.manufacture_date, _timezone, 'YYYY-MM-DD')
              resData.gateway.updated_at = DateTools.dateTimeZone(resData.gateway.updated_at, _timezone, 'YYYY-MM-DD HH:mm')
              resData.gateway.created_at = DateTools.dateTimeZone(resData.gateway.created_at, _timezone, 'YYYY-MM-DD HH:mm')
            }
          }

          resolve(resData);
        })
        .catch(err => {
          reject(err);
        })
    })
  },

  get_Gateways(context, settings) {
    return new Promise((resolve, reject) => {

      GatewayAPI.api_Get_Gateways(settings.query)
        .then(res => {
          let resData = res.data
          let _hashids = []

          if (resData) {
            if (resData.gateways) {
              let _timezone = this.state.user.timezone;  // 取得其他 namespace 的 state

              resData.gateways.forEach(_gateway => {
                if (_hashids.indexOf(_gateway.hashid) === -1) _hashids.push(_gateway.hashid)

                _gateway.manufacture_date = DateTools.dateTimeZone(_gateway.manufacture_date, _timezone, 'YYYY-MM-DD')
                _gateway.updated_at = DateTools.dateTimeZone(_gateway.updated_at, _timezone, 'YYYY-MM-DD')
                _gateway.created_at = DateTools.dateTimeZone(_gateway.created_at, _timezone, 'YYYY-MM-DD')
              })
            }
          }

          // 設定 Hashid 資訊：呼叫 Service Unit API 後才返還 res
          if (_hashids.length > 0) {
            const _query = {}
            _query.hashids = _hashids.join(',')

            this.dispatch('service_unit/get_ServiceUnits', { query: _query })
              .then(r => {
                // 把 SUID 資料填回去
                r.service_units.forEach(_suid => {

                  let _index_of_device = _hashids.indexOf(_suid.hashid)

                  if (_index_of_device !== -1) resData.gateways[_index_of_device].suid = _suid
                })

                resolve(resData);
              })
              .catch(e => { resolve(resData); })
          }
          else resolve(resData);
        })
        .catch(err => {
          reject(err);
        })
    })
  },

}

// mutations
const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
