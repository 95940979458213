

const PendingDevices = () => import('@/views/report/Pending_Devices')

export default [
    {
        path: 'report',
        redirect: '/report/pending-devices',
        name: 'Report',
        component: {
        render (c) { return c('router-view') }
        },
        children: [
            {
                path: 'pending-devices',
                name: 'Pending Devices',
                component: PendingDevices
            },
        ]
    },
]