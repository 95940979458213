export default {

    getInputMaxLength (model, inputType)
    {
        switch (model)
        {
            case 'user':        return this._getInputMaxLength_user(inputType);
            case 'project':     return this._getInputMaxLength_project(inputType);
            case 'cropperiod':  return this._getInputMaxLength_cropperiod(inputType);
            case 'device':      return this._getInputMaxLength_device(inputType);
            case 'alert':       return this._getInputMaxLength_alert(inputType);

            default: return 255;
        }
    },

    _getInputMaxLength_user (inputType)
    {
        switch (inputType)
        {
            case 'last_name': return 50;
            case 'first_name': return 50;
            case 'phone': return 25;
            case 'address': return 255;
            case 'company': return 255;
            default: return 255;
        }
    },
    _getInputMaxLength_project (inputType)
    {
        switch (inputType)
        {
            case 'name': return 255;
            case 'timezone': return 25;
            case 'address': return 255;
            case 'location': return 64;
            default: return 255;
        }
    },
    _getInputMaxLength_cropperiod (inputType)
    {
        switch (inputType)
        {
            case 'name': return 255;
            case 'farm_field': return 255;
            case 'weather_location': return 64;
            default: return 255;
        }
    },
    _getInputMaxLength_device (inputType)
    {
        switch (inputType)
        {
            case 'hashid': return 8;
            case 'nickname': return 32;
            case 'sensor_params': return 512;
            case 'visualization': return 512;

            case 'photo_note': return 255;
            default: return 255;
        }
    },
    _getInputMaxLength_alert (inputType)
    {
        switch (inputType)
        {
            case 'message': return 32;
            default: return 255;
        }
    },

    /**
     * integer 格式檢查
     * @param {string} _integer
     */
    checkInteger (_integer)
    {
        if (_integer === '')   return undefined
        if (_integer === null) return undefined  // nullable

        let reg = /^[-+]?[0-9]+$/;
        // 可接受：-123、123、0
        if ( reg.test(_integer) )
        {
            return true
        }
        return false
    },

    /**
     * float 格式檢查
     * @param {string} _float
     */
    checkFloat (_float)
    {
        if (_float === '')   return undefined
        if (_float === null) return undefined  // nullable

        let reg = /^[0-9]+(\.[0-9]+)?$/;
        // 可接受：123、123.0、123.456
        // 不接受：123.、-123、+123、123.123.123
        if ( reg.test(_float) )
        {
            return true
        }
        return false
    },

    /**
     * email 格式檢查: @ 後面一定有 . 分隔，並且 . 後面為 2 ~ 6 碼
     * @param {string} _email
     */
    checkEmail (_email)
    {
        if (_email === '') return undefined

        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/.test(_email))
        {
            return true
        }

        return false
    },

    /**
     * password 格式檢查: 大小寫英文 + 數字，長度 6 ~ 20
     * @param {string} _password
     */
    checkPassword (_password)
    {
        let reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
        if ( _password.match(reg) )
        return true

        return false
    },

    /**
     * 使用者姓/名檢查：根據 _nullable 判斷是否要檢查空值，預設名字長度最少為 1
     * @param {string} _name - 姓名字串
     * @param {integer} [_length = 1] - 要檢查的最小長度
     * @param {boolean} [_nullable = false] - 是否可以不填寫(是否必填)；可以不填寫的情況下，空值輸入框不會變成紅色
     */
    checkName (_name, _length = 1, _nullable = true)
    {
        if ( !_name && _nullable ) { return undefined; }
        if ( !_name ) { return false; }

        return _name.length >= _length ? true : false;
    },

    /**
     * 輸入為【 必填 】項目的檢查，避免填入無意義的文字，最少要四個字
     * @param {string} _text
     */
    checkRequired (_text)
    {
        if (!_text) return false;
        if (_text.length < 4) return false;
        return true;
    },

    /**
     * 密碼三重檢查：[ idx=0 新密碼, idx=1 新密碼(輸入第二次), idx=2 舊密碼(checkOld = false 時可不填) ]
     *  - 註冊、忘記密碼：      [ 新密碼, 新密碼(輸入第二次) ]
     *  - 使用者資料重設密碼：  [ 新密碼, 新密碼(輸入第二次), 舊密碼 ]
     * @param {integer} idx - 要檢查的是 passwordList 中的哪個密碼
     * @param {array} passwordList - 密碼清單，傳入 array 長度至少 2
     * @param {object} p_err - 各項密碼檢查相關的錯誤檢查確認清單 = { boolean, boolean, ... }
     * @param {boolean} [checkOld = false] - 是否要檢查舊密碼，預設為不檢查；要檢查的情形下，passwordList 傳入 array 長度一定要是 3
     */
    checkPasswordList (idx, passwordList, p_err, checkOld = false)
    {
        if (idx === 0) // new password
        {
            if ( passwordList[0].length === 0 ) return undefined

            p_err.new_format_err = ! this.checkPassword( passwordList[0] );

            let _otherErr = false;
            if (checkOld)
            {
                p_err.old_new_same = ( passwordList[0] === passwordList[2] );
                _otherErr = p_err.old_new_same
            }

            if (p_err.new_format_err || _otherErr) return false

        }
        else if (idx === 1) // new password again
        {
            if ( passwordList[1].length === 0 ) return undefined

            p_err.new_not_same = ! ( passwordList[0] === passwordList[1] );
            if ( p_err.new_not_same ) return false
        }
        else if (idx === 2) // old password
        {
            if ( passwordList[2].length === 0 )
            {
                p_err.old_required = ( passwordList[0].length > 0 || passwordList[1].length > 0 )
                if ( p_err.old_required ) return false
            }
            else p_err.old_required = false
        }
        return undefined
    },
    /**
     * 回傳是否【顯示密碼】明碼的按鈕樣式
     * @param {boolean} btn_showPassword - 是否顯示明碼
     */
    showPasswordBtn (btn_showPassword)
    {
        let _pwdStyle = {
            class: 'fa fa-eye-slash',
            inputType: 'password',
        }

        if (btn_showPassword)
        {
            _pwdStyle.class = 'fa fa-eye'
            _pwdStyle.inputType = 'text'
        }

        return _pwdStyle
    },

    /**
     * 檢查選擇到的新、舊檔案
     * @param {object} newFile - 新檔案
     * @param {object} oldFile - 舊檔案
     * @param {*} prevent - vue.js 的 event.preventDefault()
     * @param {object} Vue - 要使用到 vue 的工具因此需要傳入
     */
    check_inputFile (newFile, oldFile, prevent, Vue, editType = undefined)
    {
        if (newFile && !oldFile) {
            Vue.$nextTick(function () {
                if (editType) Vue.edit[ editType ] = true;
                else Vue.edit = true;
            })
        }
        if (!newFile && oldFile) {
            if (editType) Vue.edit[ editType ] = false;
            else Vue.edit = false;
        }
    },
    /**
     * 檢查圖檔的格式
     * @param {object} newFile - 新檔案
     * @param {object} oldFile - 舊檔案
     * @param {*} prevent - vue.js 的 event.preventDefault()
     * @param {object} Vue - 要使用到 vue 的工具因此需要傳入
     */
    image_inputFilter (newFile, oldFile, prevent, Vue, checkSize = false)
    {
        // console.log( 'size: ' + newFile.size )
        // 判斷檔案最大為 2MB
        if ( checkSize === true && newFile.size > 2000000 )
        {
            // Vue.pic_err = true;
            alert( Vue.$t('other.upload_image_max_size') );
            return prevent();
        }

        if (newFile && !oldFile) {
            if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name))
            {
                Vue.pic_err = true;
                return prevent();
            }
        }
        if (newFile && (!oldFile || newFile.file  !==  oldFile.file))
        {
            newFile.url = '';
            Vue.pic_err = false;
            let URL = window.URL || window.webkitURL;
            if (URL && URL.createObjectURL) {
                newFile.url = URL.createObjectURL(newFile.file);
            }
        }
    },
    randomCode ()
    {
        let _code = '';
        let _codeLength = 6; // 驗證碼的長度

        let _selectChar = new Array(0,1,2,3,4,5,6,7,8,9,'A','B','C','D','E','F','G','H','J','K','L','M','N','P','Q','R','S','T','U','V','W','X','Y','Z');
        for (let i=0; i<_codeLength; i++)
        {
            let _charIndex = Math.floor( Math.random() * 32 );
            _code += _selectChar[ _charIndex ];
        }
        return _code;
    },
    randomNumber (max = 1000)
    {
        return Math.floor( Math.random() * max ) + 1;
    },
}
