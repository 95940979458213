import request from './_https';
// querystring 在 node 14 後已經棄用，改為 URLSearchParams
import setQueryString from '../tools/helpers';

/** --------------------
 *  Normal
 *  ----------------- */

export const api_Get_AccessPoints = (query = undefined) => request('get', `/accesspoints${setQueryString(query)}`);
export const api_Get_AccessPoint_Detail = (params) => request('get', `/accesspoints/${params.hashID}`);
export const api_Update_AccessPoint = (params, data) => request('put', `/accesspoints/${params.hashID}`, data);

export const api_Get_AccessPoint_Records = (params, query = undefined) => request('get', `/accesspoints/${params.hashID}/records${setQueryString(query)}`);

export const api_Store_AccessPoint_Pic = (params, data) => {
  const _formData = new FormData();
  _formData.append('pic', data.pic);

  const _config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  return request('post', `/accesspoints/${params.hashID}/pic`, _formData, _config);
};

/** --------------------
 *  Admin
 *  ----------------- */

export const api_Create_AccessPoint = (data) => request('post', '/admin/accesspoints', data);
export const api_Delete_AccessPoint = (params) => request('delete', `/admin/accesspoints/${params.hashID}`);
