

import DateTools from '@/tools/date'
import * as AccessPointAPI from '@/apis/accesspoint'

// initial state
const state = {

    list_attribute: [
        { value: 'is_refurbish' },
        { value: 'is_rent' },
        { value: 'attention' },
    ],

    list_hw_version: [
        { text: '--：其他，待新增選項', value: 0 },
        { text: 'V1：初代', value: 1 },
    ],

}

// getters
const getters = {

}

// actions
const actions = {

    get_AccessPoint_Records (context, settings)
    {
        return new Promise((resolve, reject) => {

            AccessPointAPI.api_Get_AccessPoint_Records(settings.params, settings.query)
                .then( res => {
                    let resData = res.data

                    if ( resData )
                    {
                        if ( resData.records )
                        {
                            let _timezone = this.state.user.timezone;  // 取得其他 namespace 的 state

                            resData.records.forEach( _record => {
                                _record.record_time = DateTools.dateTimeZone( _record.record_time, _timezone, 'YYYY-MM-DD HH:mm' )
                                _record.updated_at  = DateTools.dateTimeZone( _record.updated_at,  _timezone, 'YYYY-MM-DD HH:mm' )
                            })
                        }
                    }


                    resolve(resData);
                })
                .catch( err => {
                    reject(err);
                })
        })
    },

    get_AccessPoint_Detail (context, settings)
    {
        return new Promise((resolve, reject) => {

            AccessPointAPI.api_Get_AccessPoint_Detail(settings.params)
            .then( res => {
                let resData = res.data

                if ( resData )
                {
                    if ( resData.accesspoint )
                    {
                        let _timezone = this.state.user.timezone;  // 取得其他 namespace 的 state

                        resData.accesspoint.category = 'accesspoint'
                        resData.accesspoint.manufacture_date = DateTools.dateTimeZone( resData.accesspoint.manufacture_date, _timezone, 'YYYY-MM-DD' )
                        resData.accesspoint.updated_at  = DateTools.dateTimeZone( resData.accesspoint.updated_at,  _timezone, 'YYYY-MM-DD HH:mm' )
                        resData.accesspoint.created_at  = DateTools.dateTimeZone( resData.accesspoint.created_at,  _timezone, 'YYYY-MM-DD HH:mm' )
                    }
                }

                resolve(resData);
            })
            .catch( err => {
                reject(err);
            })
        })
    },

    get_AccessPoints (context, settings)
    {
        return new Promise((resolve, reject) => {

            AccessPointAPI.api_Get_AccessPoints(settings.query)
            .then( res => {
                let resData = res.data
                let _hashids = []

                if ( resData )
                {
                    if ( resData.accesspoints )
                    {
                        let _timezone = this.state.user.timezone;  // 取得其他 namespace 的 state

                        resData.accesspoints.forEach( _accesspoint => {
                            if ( _hashids.indexOf( _accesspoint.hashid ) === -1 ) _hashids.push( _accesspoint.hashid )

                            _accesspoint.manufacture_date = DateTools.dateTimeZone( _accesspoint.manufacture_date, _timezone, 'YYYY-MM-DD' )
                            _accesspoint.updated_at  = DateTools.dateTimeZone( _accesspoint.updated_at,  _timezone, 'YYYY-MM-DD' )
                            _accesspoint.created_at  = DateTools.dateTimeZone( _accesspoint.created_at,  _timezone, 'YYYY-MM-DD' )
                        })
                    }
                }

                // 設定 Hashid 資訊：呼叫 Service Unit API 後才返還 res
                if ( _hashids.length > 0 )
                {
                    const _query = {}
                    _query.hashids = _hashids.join(',')

                    this.dispatch('service_unit/get_ServiceUnits', { query: _query })
                    .then(  r => {
                        // 把 SUID 資料填回去
                        r.service_units.forEach( _suid => {

                            let _index_of_device = _hashids.indexOf( _suid.hashid )

                            if ( _index_of_device !== -1 ) resData.accesspoints[ _index_of_device ].suid = _suid
                        })

                        resolve(resData);
                    })
                    .catch( e => { resolve(resData); })
                }
                else resolve(resData);
            })
            .catch( err => {
                reject(err);
            })
        })
    },

}

// mutations
const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
