import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import device from './modules/device'
import camera from './modules/camera'
import gateway from './modules/gateway'
import accesspoint from './modules/accesspoint'
import system from './modules/system'
import ui from './modules/ui'
import data from './modules/data'
import define from './modules/define'
import record from './modules/record'
import management from './modules/management'
import customer from './modules/customer'
import reservation from './modules/reservation'
import material from './modules/material'
import service_unit from './modules/service_unit'
import shipment from './modules/shipment'


Vue.use(Vuex)

const debug = process.env.NODE_ENV  !==  'production'

export default new Vuex.Store({
    modules: {
        user,
        device,
        camera,
        gateway,
        accesspoint,
        system,
        ui,
        data,
        define,
        record,
        management,
        customer,
        reservation,
        material,
        service_unit,
        shipment,
    },
    strict: debug,
    //plugins: debug ? [createLogger()] : []
})
