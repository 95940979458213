

export default {

    check_Quantity(quantity, min=0)
    {
        if ( quantity === '' ) quantity = 1
        if ( quantity === null ) quantity = 1
        if ( quantity === undefined ) quantity = 1
        if ( parseInt( quantity ) < 1 ) quantity = 1
        quantity = parseInt( quantity )
    },

    /**
     * 取得兩個數值之間的隨機 [ 數值 ]
     * @param {integer/float} min
     * @param {integer/float} max
     */
    getRandomArbitrary: function (min, max)
    {
        return Math.random() * (max - min) + min;
    },

    /**
     * 取得兩個數值之間的隨機 [ 整數 ]，不包含該兩數
     * @param {integer/float} min
     * @param {integer/float} max
     * @param {boolean} [include = true] - 是否要包含該兩個數值，預設為 true 要包含
     */
    getRandomInt: function (min, max, include = true)
    {
        min = Math.ceil(min);
        max = Math.floor(max);

        if (include)
        {
            // The maximum is inclusive and the minimum is inclusive
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }

        // The maximum is exclusive and the minimum is inclusive
        return Math.floor(Math.random() * (max - min)) + min;
    },

    // --------------------------------------
    // Display 數值計算、呈現相關
    // --------------------------------------

    /**
     * transferNumberFormat ()
     * 將傳入的數字轉成千分位號
     *
     * @param {String} number
     * @return {Element} element
     */
    transferNumberFormat: function (number)
    {
        number = fixFloat(number);

        let _number = Intl.NumberFormat('en-IN').format( number );
        if ( _number === 'NaN' ) { return number;  }
        else                     { return _number; }
    },


    /**
     * checkNumber ()
     * 檢查傳入的數值是否為數字
     *
     * @param {Float} value
     * @return {Boolean} value
     */
    checkNumber: function (value)
    {
        // 因為會有叫做 'NaN'、'null'、'undefined' 的字串，所以要轉字串後過濾
        if (value === NaN)                       return false;
        if (value === null)                      return false;
        if (value === undefined)                 return false;
        if (value.toString() === 'NaN')          return false;
        if (value.toString() === 'null')         return false;
        if (value.toString() === 'undefined')    return false;
        if (value.toString() === 'NA')           return false;

        //console.log('check type:');
        //if ( typeof value === 'number' )         return true;

        return true;
    },

    /**
     * checkShowData ()
     * 將傳入的數值檢查後加上傳入文字
     *
     * @param {Float} value
     * @param {String} postWord = ''
     * @return {String} _showData
     */
    checkShowData: function (value, postWord = '')
    {
        let _showData = '無資料';
        //console.log( 'value: ' + value );
        //console.log( 'typeof value: ' + (typeof value) );
        value = transferNumberFormat( value );

        // 因為會有叫做 'NaN'、'null'、'undefined' 的字串，所以要轉字串後過濾
        if (value !== undefined && value !== null && value !== NaN && value.toString() !== 'NaN' && value.toString() !== 'null' && value.toString() !== 'undefined' )
        {
            _showData = ( value + postWord );
        }

        return _showData;
    },


    /**
     * fixFloat ()
     * 將傳入的數值限制保留到小數點後面 floatFixNumber 位數
     *
     * @param {Float} value
     * @return {Float} value
     */
    fixFloat: function (value, _floatFixNumber = floatFixNumber)
    {
        if ( checkNumber(value) === true ) return parseFloat( value.toFixed(_floatFixNumber) );
        else return value;
    },


    /**
     * averageData()
     * 將傳入的 array 數值加總後平均
     * @param {Array} _array
     * @return {Float} fixFloat( sum / _array.length )
     */
    averageData: function (_array)
    {
        let _sum = 0;
        let _count = 0;
        _array.forEach( function ( _value ) {
            if ( checkNumber(_value) === true )
            {
                _sum += _value;
                _count ++;
            }
        });
        return fixFloat( _sum / _count );
    },
}
