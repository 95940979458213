import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')
const TheDoorContainer = () => import('@/containers/TheDoorContainer')

// Views
// Views - Home
const Home = () => import('@/views/home/Home')
const Links = () => import('@/views/home/Links')

const ListUser = () => import('@/views/user/List_User')
const EditUser = () => import('@/views/user/Edit_User')


// views - Under Home
import route_users from './under_home/users'

import route_data from './under_home/data'
import route_records from './under_home/records'
import route_devices from './under_home/devices'
import route_gateways from './under_home/gateways'
import route_accesspoints from './under_home/accesspoints'
import route_customers from './under_home/customers'

// view - Tools
import route_report from './under_home/report'

// views - Admin
import route_lord from './admin/lord'


// views - Door Pages
const SocialLoginBinding = () => import('@/views/pages/SocialLogin_Binding')
import route_pages from './door_page/pages'
import route_public_pages from './door_page/public_pages'


// views_coreui
const Dashboard = () => import('@/views_coreui/Dashboard')
const StandardButtons = () => import('@/views_coreui/buttons/StandardButtons')
// const Users = () => import('@/views_coreui/users/Users')
// const User = () => import('@/views_coreui/users/User')



const scrollBehavior = function (to, from, savedPosition)
{
    const position = {}
    // scroll to anchor by returning the selector
    if (to.hash)
    {
        position.selector = to.hash

        // specify offset of the element
        // if (to.hash === '#anchor2')
        // {
        //   position.offset = { y: 100 }
        // }
        // else
        // {
        position.offset = { y: 100 } // 移動到該 id 的上方 100px
        // }

        if (document.querySelector(to.hash))
        {
        return position
        }
    }
    else
    {
        // 捲動到頁面最上方
        return { x: 0, y: 0 };
    }
    // 回傳空值/false 會保持上次瀏覽的捲動位置
    // if the returned position is falsy or an empty object,
    // will retain current scroll position.
    //return false
}



Vue.use(Router)

export default new Router({
    // mode: 'hash', // https://router.vuejs.org/api/#mode
    // linkActiveClass: 'active',
    mode: 'history',//'hash', // https://router.vuejs.org/api/#mode
    base: '/iguanshi',
    linkActiveClass: 'open active',
    scrollBehavior: scrollBehavior,
    routes: configRoutes()
})

function configRoutes () {
    let _route_settings = [
        {
            path: '/',
            redirect: '/home',
            name: 'IGuanShi',
            component: TheContainer,
            children: [
                {
                    path: 'home',
                    name: 'Home',
                    component: Home
                },
                {
                    path: 'links',
                    name: 'Links',
                    component: Links
                },
                {
                    path: 'dashboard',
                    name: 'Dashboard',
                    component: Dashboard
                },
                {
                    path: 'users',
                    redirect: '/users/list',
                    name: 'Users',
                    component: {
                    render (c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'User List',
                            component: ListUser
                        },
                        {
                            path: 'edit',
                            name: 'User New',
                            component: EditUser
                        },
                        {
                            path: 'edit/:userID',
                            name: 'User Edit',
                            component: EditUser
                        },
                    ]
                },
                {
                    path: 'buttons',
                    redirect: '/buttons/standard-buttons',
                    name: 'Buttons',
                    component: {
                    render (c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: 'standard-buttons',
                            name: 'Standard Buttons',
                            component: StandardButtons
                        },
                    ]
                },
            ]
        },
        {
            path: '/app',
            name: 'APP',
            component: TheDoorContainer,
            children: [
                {
                    path: 'social_login_binding/:socialType',
                    name: 'Social Login Binding',
                    component: SocialLoginBinding
                },
            ]
        },
    ]

    
    // views - Under Home
    route_users.forEach( _item => { _route_settings[0].children.push( _item ) })
    route_data.forEach( _item => { _route_settings[0].children.push( _item ) })
    route_records.forEach( _item => { _route_settings[0].children.push( _item ) })
    route_devices.forEach( _item => { _route_settings[0].children.push( _item ) }) 
    route_gateways.forEach( _item => { _route_settings[0].children.push( _item ) })
    route_accesspoints.forEach( _item => { _route_settings[0].children.push( _item ) }) 
    route_customers.forEach( _item => { _route_settings[0].children.push( _item ) })

    // view - Tools
    route_report.forEach( _item => { _route_settings[0].children.push( _item ) })
    
    // views - Admin
    route_lord.forEach( _item => { _route_settings[0].children.push( _item ) })

    // views - Door Pages
    route_pages.forEach( _item => { _route_settings.push( _item ) })
    route_public_pages.forEach( _item => { _route_settings.push( _item ) })
    
    return _route_settings
}

