
import router from '@/router'
import DateTools from '@/tools/date'
import * as UserAPI from '@/apis/user'

// initial state
const state = {
    default_avatar: 'https://www.gravatar.com/avatar/default?s=150&r=pg&d=mm',

    is_developer: false,
    is_admin: undefined,
    id: undefined,
    name: undefined,
    email: undefined,
    phone: undefined,
    address: undefined,
    pic: undefined,
    company: undefined,
    language: 'en',
    timezone: 'Asia/Taipei',
    createed_at: undefined,
    updated_at: undefined,
    preference: {},

    list_status: [
        {
            value: 'enable',
            buttonVariant: 'outline-success'
        },
        {
            value: 'disabled',
            buttonVariant: 'outline-danger'
        },
        {
            value: 'deleted',
            buttonVariant: 'outline-dark'
        },
    ],

    // 用來對應 user 資料
    map_users: {},

    location_history: undefined,

    // Navigator
    userBrowser: undefined,
    appVersion: undefined,
    platform: undefined,
}

// getters
const getters = {

    list_To_count_userids: (state) => (list, keywords=[], result_type = 'query') => {
        // keywords 可能是 executor, recorder, principal, ...

        let _user_ids = []
        if ( list && list.length > 0 )
        {
            list.forEach( _item => {

                keywords.forEach( _key => {
                    if ( list[ _key ] !== undefined && _user_ids.indexOf( list[ _key ] ) === -1 ) // && state.map_users[ list[_key] ] === undefined
                    {
                        _user_ids.push( list[ _key ] )
                    }
                })
            })
        }

        if ( result_type === 'query' )
        {
            if ( _user_ids.length > 0 )
            {
                return { ids: _user_ids.join(',') }
            }
            else return undefined
        }
        else return _user_ids
    },

}

// actions
const actions = {

    admin_get_Users (context, settings)
    {
        return new Promise((resolve, reject) => {

            UserAPI.api_Get_Users(settings.query)
            .then( res => {
                let resData = res.data

                if ( resData )
                {
                    if ( resData.users )
                    {
                        let _timezone = this.state.user.timezone;  // 取得其他 namespace 的 state

                        resData.users.forEach( _user => {
                            _user.updated_at  = DateTools.dateTimeZone( _user.updated_at,  _timezone, 'YYYY-MM-DD HH:mm' )
                            _user.created_at  = DateTools.dateTimeZone( _user.created_at,  _timezone, 'YYYY-MM-DD HH:mm' )
                        })
                    }
                }


                resolve(resData);
            })
            .catch( err => {
                reject(err);
            })
        })
    },

    get_Users (context, settings)
    {
        return new Promise((resolve, reject) => {

            UserAPI.api_Get_Users_Simple(settings.query)
            .then( res => {
                let resData = res.data

                if ( resData && resData.users ) context.commit('set_map_users', resData.users)

                resolve(resData);
            })
            .catch( err => {
                reject(err);
            })
        })
    },

    get_User_Location(context, Vue) {
        return new Promise((resolve, reject) => {

            Vue.$getLocation({
                enableHighAccuracy: true,   // defaults to false
                timeout: Infinity,          // defaults to Infinity
                maximumAge: 0,              // defaults to 0. If set to 0, it means that the device cannot use a cached position and must attempt to retrieve the real current position.
            })
            .then(coordinates => {
                // console.log(coordinates);
                resolve(coordinates);
            })
            .catch(err => {
                reject(err);
            })

        })
    },

    create_Log(context, info) {
        return new Promise((resolve, reject) => {

            // user log 都加上觀看當下的螢幕寬度
            if (info !== undefined)
            {
                if (info.detail === undefined) info.detail = {}

                // 紀錄螢幕寬度，判斷裝置型別
                let _viewWidth = document.body.clientWidth
                let _guessDeviceType = ''

                if      ( _viewWidth >= 999 ) _guessDeviceType = 'PC'
                else if ( _viewWidth >= 768 ) _guessDeviceType = 'Pad'
                else                          _guessDeviceType = 'Phone'

                info.detail.screenWidth     = _viewWidth + ' px'
                info.detail.guessDeviceType = _guessDeviceType

                // 紀錄瀏覽器資訊
                info.detail.userBrowser     = context.state.userBrowser
                info.detail.browser_version = context.state.appVersion
                info.detail.platform        = context.state.platform
            }

            UserAPI.api_Create_OPLog(info)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                })
        })
    },

    upload_Avatar (context, file) {
        return new Promise((resolve, reject) => {

            let _formData = new FormData();
            _formData.append('avatar', file);

            const options = {
                method: 'POST',
                url: AgriConfig.getAPI_URL('Avatar'),
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: _formData,
            }
            Axios(options)
                .then(res => {
                    context.commit('set_Avatar', res.data.link);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                })
        })
    },

    get_User_Profile (context) {
        return new Promise((resolve, reject) => {

            UserAPI.api_Get_User_Profile()
                .then(res => {
                    // console.log(res.data);
                    context.commit('update_Data', res.data);
                    resolve(res);
                })
                .catch(err => {
                    //console.log('取得使用者資料 失敗');
                    reject(err);
                })
        })
    },

    refresh_Token (context) {
        return new Promise((resolve, reject) => {
            // 檢查 token 是否存在
            if (localStorage.token) {
                // 存在 token 就利用 refresh token 來檢查是否有效
                UserAPI.api_Refresh_Token()
                    .then(res => {
                        context.commit('refresh_Token', res)
                        resolve(res);
                        // else
                        // {
                        // 沒有更新 token 就執行登出動作
                        // context.commit('clean_Data');    // 清除資料
                        // context.commit('action_Logout'); // 執行登出動作
                        // reject(res);
                        // }
                    })
                    .catch(err => {
                        reject(err);
                    })
            }
        })
    },

    logout (context) {
        return new Promise((resolve, reject) => {

            context.commit('clean_Data'); // 清除資料

            if (!localStorage.token) resolve(res); // 沒有 token 就不往下執行

            UserAPI.api_Logout_User()
                .then(res => {
                    context.commit('action_Logout'); // 執行登出動作
                    resolve(res);
                })
                .catch(err => {
                    context.commit('action_Logout'); // 執行登出動作
                    reject(err);
                })
        })
    },

    set_Language(context, lang) {

        return new Promise((resolve, reject) => {
            const _data = {
                lang: lang,
            }
            UserAPI.api_Update_User_Language(_data)
                .then(res => {

                    // 更新語言
                    context.commit('set_Language', lang);
                    resolve();
                })
                .catch(err => {
                    reject(err);
                })
        })
    },


}

// mutations
const mutations = {

    set_map_users (state, data)
    {
        data.forEach( _user_data => {
            state.map_users[ _user_data.id ] = _user_data
        })
        // console.log( state.map_users )
    },

    // 檢查瀏覽器資訊
    check_Browser (state) {

        // Get the user-agent string
        let userAgentString = window.navigator.userAgent;

        // Detect Chrome
        let chromeAgent =
            userAgentString.indexOf("Chrome") > -1;

        // Detect Internet Explorer
        let IExplorerAgent =
            userAgentString.indexOf("MSIE") > -1 ||
            userAgentString.indexOf("rv:") > -1;

        // Detect Firefox
        let firefoxAgent =
            userAgentString.indexOf("Firefox") > -1;

        // Detect Safari
        let safariAgent =
            userAgentString.indexOf("Safari") > -1;

        // Discard Safari since it also matches Chrome
        if ((chromeAgent) && (safariAgent))
            safariAgent = false;

        // Detect Opera
        let operaAgent =
            userAgentString.indexOf("OP") > -1;

        // Discard Chrome since it also matches Opera
        if ((chromeAgent) && (operaAgent))
            chromeAgent = false;


        if ( safariAgent )      state.userBrowser = 'Safari'
        if ( chromeAgent )      state.userBrowser = 'Chrome'
        if ( IExplorerAgent )   state.userBrowser = 'IE'
        if ( operaAgent )       state.userBrowser = 'Opera'
        if ( firefoxAgent )     state.userBrowser = 'Firefox'

        // console.log( userAgentString )
        // console.log( 'Browser: ' + state.userBrowser )

        state.appVersion = window.navigator.appVersion   // 瀏覽器版本
        state.platform = window.navigator.platform       // 裝置平台
    },

    set_Avatar(state, avatar) {
        state.pic = avatar;
        if (state.pic) state.pic += '?' + Math.random();
    },
    set_Language(state, lang) {
        state.language = lang;
    },
    update_Data(state, data) {
        state.is_admin = data.is_admin;
        state.id = data.id;
        state.name = data.name;
        state.email = data.email;
        state.phone = data.phone;
        state.address = data.address;
        state.company = data.company;
        state.language = data.language;
        state.createed_at = data.createed_at;
        state.updated_at = data.updated_at;
        state.preference = ( data.preference ? data.preference : {} );

        state.pic = data.pic;
        if (state.pic) state.pic += '?' + Math.random();

        switch (state.email) {
            case 'iris@beehivedt.com':
            case 'tuvix@beehivedt.com':
            case 'chaofeng@beehivedt.com':
            case 'winnie@beehivedt.com':
                state.is_developer = true;
                break;

            default:
                state.is_developer = false;
                break;
        }
    },
    clean_Data(state, data) {
        state.is_developer = false;
        state.is_admin = false;
        state.id = undefined;
        state.name = undefined;
        state.email = undefined;
        state.phone = undefined;
        state.address = undefined;
        state.pic = undefined;
        state.company = undefined;
        state.language = undefined;
        state.createed_at = undefined;
        state.updated_at = undefined;
    },

    set_History (state, url)
    {
        state.location_history = url
    },
    action_Logout() {
        this.commit('user/clean_Data', undefined);       // 清楚使用者資料
        localStorage.removeItem('token');               // 清空 token
        router.replace({ name: 'Login' });              // 導向登入頁面
    },
    refresh_Token(state, response) {
        // 因為 development 版本為 Localhost 會撈不到 header 帶的 token
        if (process.env.NODE_ENV === 'development') {
            // 如果回傳的資料有 access_token 表示登入成功
            let token = response.data.access_token
            if (token) {
                // console.log('更新 token in development');
                // console.log(token);
                localStorage.token = 'bearer ' + token;
                return true
            }
            return false
        }

        // 如果回傳資料的 header 有帶 token，表示後端已經幫忙更新一支新的 token 了，有拿到就存下來
        if (response.headers && response.headers.authorization) {
            let token = response.headers.authorization
            if (token) {
                // console.log('更新 token in production');
                // console.log(token);
                localStorage.token = token;
                return true
            }

        }
        return false
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
