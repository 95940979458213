

export default {

    /*********************************
     * 單位轉換工具：重量
     *********************************/
    weight_units: {
        default: [ 'metric_ton', 'kg', 'g' ],
        taiwan:  [ 'metric_ton', 'kg', 'g', 'tw_weight' ],
    },
    weight_unit_to_kg (unit, value)
    {
        if (value)
        {
            switch (unit)
            {
                case 'metric_ton': return ( value * 1000 );         // 1 公頓(metric ton) = 1000 kg
                case 'g': return ( value * 0.001 );                 // 1 公克(g) = 0.001 kg
                case 'tw_weight': return ( value * 0.6 );       // 1 台斤 = 0.6 kg
                default: return value;
            }
        }
        else return value;
    },
    weight_kg_to_unit (unit, value)
    {
        if (value)
        {
            switch (unit)
            {
                case 'metric_ton': return ( value * 0.001 );        // 1 公頓(metric ton) = 1000 kg
                case 'g': return ( value * 1000 );                  // 1 公克(g) = 0.001 kg
                case 'tw_weight': return ( value / 0.6 );       // 1 台斤 = 0.6 kg
                default: return value;
            }
        }
        else return value;
    },
    weight_unit_convert (from_unit, to_unit, value)
    {
        // 先把原始單位轉為 kg
        let _value_kg = this.weight_unit_to_kg( from_unit, value );

        // 再從 kg 轉為需要的單位
        return this.weight_kg_to_unit( to_unit, _value_kg );
    },




    /*********************************
     * 單位轉換工具：面積
     *********************************/
    area_units: {
        default: [ 'm2', 'ha', 'acre' ],
        taiwan:  [ 'm2', 'ha', 'hun', 'kah', 'acre' ],
    },
    area_unit_to_m2 (unit, value)
    {
        if (value)
        {
            switch (unit)
            {
                case 'ha': return ( value * 10000 );        // 1 公頃(ha) = 10000 m2
                case 'acre': return ( value * 4046.86 );    // 1 英畝(acre) = 4046.86 m2
                case 'hun': return ( value * 969.917 );     // 1 分(hun) = 969.917 平方公尺
                case 'kah': return ( value * 9699.17 );     // 1 甲(kah) = 9699.17 平方公尺
                default: return value;
            }
        }
        else return value;
    },
    area_m2_to_unit (unit, value)
    {
        if (value)
        {
            switch (unit)
            {
                case 'ha': return ( value / 10000.0 );      // 1 公頃(ha) = 10000 m2
                case 'acre': return ( value / 4046.86 );    // 1 英畝(acre) = 4046.86 m2
                case 'hun': return ( value / 969.917 );     // 1 分(hun) = 969.917 平方公尺
                case 'kah': return ( value / 9699.17 );     // 1 甲(kah) = 9699.17 平方公尺
                default: return value;
            }
        }
        else return value;
    },
    area_unit_convert (from_unit, to_unit, value)
    {
        // 先把原始單位轉為 m2
        let _value_m2 = this.area_unit_to_m2( from_unit, value );

        // 再從 m2 轉為需要的單位
        return this.area_m2_to_unit( to_unit, _value_m2 );
    },
    density_unit_convert (from_unit, to_unit, value)
    {
        if (value)
        {
            // 先把原始單位轉為 m2
            let _value_m2 = this.area_unit_to_m2( from_unit, 1 );

            // 再從 m2 轉為需要的單位
            return value / this.area_m2_to_unit( to_unit, _value_m2 );
        }
        else return value;
    },

}