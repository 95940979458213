/// 封裝 axios，設置攔截器，統一管理錯誤

import axios from 'axios';
import store from '@/store';
import {
  tip, toLogin, to403Page, to404Page, to500Page,
} from './_utils';

/**
 * axios 攔截定義：請求失敗的統一處理
 * @param {Number} Status 請求失敗的狀態碼
 */
const errorHandle = (status, msg) => {
  switch (status) {
    // 400: 登入失敗，可能是帳號或密碼錯誤
    case 400:
      tip(msg);
      break;

    // 401: Backend Session 過期 => 跳轉至登入畫面
    case 401:
      tip('登入過期，請重新登入');
      setTimeout(() => {
        store.commit('user/set_History', location.href);
        toLogin();
      }, 1000);
      break;

    // 403: 權限不足
    case 403:
      to403Page();
      break;

    // 404: 請求失敗
    case 404:
      to404Page();
      break;

    // 500: 請求失敗
    case 500:
      to500Page();
      break;

    default:
      console.log(`resp 位攔截到的錯誤：${msg}`);
  }
};

const getAPI_BaseURL = function () {
  const _baseURL = process.env.VUE_APP_AGRI_API_BASE_URL;

  // Link to OPS for Debug
  // if (location.hostname === 'localhost') _baseURL = 'https://ops.agriweather.online/api/';

  return _baseURL;
};

/**
 * 新建axios實例，設置攔截器
 */
// axios 實例
const instance = axios.create({
  baseURL: getAPI_BaseURL(), // '/api/',
  timeout: 60 * 1000,
  headers: {
    Accept: 'application/json', // 讓後端知道式 API 呼叫，不自動導向，而是回傳 json
    'Content-Type': 'application/json', // 聲明 data 格式為 json
  },
});

// request 攔截器
// 在發送请求之前做某件事
instance.interceptors.request.use(
  (config) => {
    // token
    if (localStorage.token) {
      config.headers.Authorization = localStorage.token;
    }

    if (store.state.user.language) {
      // console.log( store.state.user.language )
      config.headers['X-localization'] = store.state.user.language;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// response 攔截器
instance.interceptors.response.use(
  (response) => {
    // 如果 header 中存在 token，那么触发 refreshToken 方法，替换本地的 token
    store.commit('user/refresh_Token', response);
    return response;
  },
  (error) => {
    const { response } = error;

    // 成功發出請求，有收到 response，有 error
    if (response) {
      errorHandle(response.status, response.data.message);
      return Promise.reject(error);
    }
    // 成功發出請求，但沒收到 response

    if (!window.navigator.onLine) {
      // 網路斷線
      tip('網路斷線中，請連線後重新整理頁面');
    } else {
      // 可能是跨域或程式問題
      return Promise.reject(error);
    }
  },
);

// 封裝函數
export default function (method, url, data = null, config = undefined) {
  method = method.toLowerCase();
  if (method === 'post') {
    return instance.post(url, data, config);
  }
  if (method === 'get') {
    return instance.get(url, config);
  }
  if (method === 'delete') {
    return instance.delete(url, config);
  }
  if (method === 'put') {
    return instance.put(url, data, config);
  }
  console.error(`未知的 method: ${method}`);
  return false;
}
