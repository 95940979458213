import request from './_https';
// querystring 在 node 14 後已經棄用，改為 URLSearchParams
import setQueryString from '../tools/helpers';

/** --------------------
 *  Normal
 *  ----------------- */

export const api_Get_Records = (query = undefined) => request('get', `/customer_records${setQueryString(query)}`);

export const api_Get_Record = (params) => request('get', `/customer_records/${params.recordID}`);
export const api_Create_Record = (data) => request('post', '/customer_records', data);
export const api_Update_Record = (params, data) => request('put', `/customer_records/${params.recordID}`, data);
export const api_Delete_Record = (params) => request('delete', `/customer_records/${params.recordID}`);
export const api_Delete_Record_Pic = (params) => request('delete', `/customer_records/${params.recordID}/pic`);

export const api_Store_Record_Pic = (params, data) => {
  const _formData = new FormData();
  _formData.append('pic', data.pic);

  const _config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  return request('post', `/customer_records/${params.recordID}/pic`, _formData, _config);
};
