

import DateTools from '@/tools/date'
import * as ReservationAPI from '@/apis/reservation'

// initial state
const state = {

    list_sort: [
        { value: '-id',                 text: 'id 降序' },
        { value: 'id',                  text: 'id' },

        { value: '-exe_date',           text: '預計執行日 降序' },
        { value: 'exe_date',            text: '預計執行日' },

        { value: '-due_date',   text: '預定完成日 降序' },
        { value: 'due_date',    text: '預定完成日' },
    ],

    list_reservation_type: [
        { text: '新品出貨', value: 'ship' },
        { text: '維修/升級', value: 'repair' },
    ],

    list_reservation_status: [
        { text: '預定', value: 'todo' },
        { text: '進行中', value: 'inprocess' },
        { text: '已完成', value: 'done' },
        { text: '棄用', value: 'abandon' },
    ],

    // 用來暫存要顯示的 ID
    show_id: undefined,
}



// getters
const getters = {

    map_ReservationType_TO_Name: state => (code) => {
        return state.list_reservation_type.find( item => item.value === code )
    },

    map_ReservationStatus_TO_Name: state => (code) => {
        return state.list_reservation_status.find( item => item.value === code )
    },

}

// actions
const actions = {

    get_Reservation_Detail (context, settings)
    {
        return new Promise((resolve, reject) => {

            ReservationAPI.api_Get_Reservation_Detail(settings.params)
            .then( res => {
                let resData = res.data

                if ( resData )
                {
                    if ( resData.reservation )
                    {
                        let _timezone = this.state.user.timezone;  // 取得其他 namespace 的 state

                        resData.reservation.exe_date   = DateTools.dateTimeZone( resData.reservation.exe_date, _timezone, 'YYYY-MM-DD' )
                        resData.reservation.due_date   = DateTools.dateTimeZone( resData.reservation.due_date, _timezone, 'YYYY-MM-DD' )
                        resData.reservation.created_at = DateTools.dateTimeZone( resData.reservation.created_at, _timezone, 'YYYY-MM-DD HH:mm' )
                        resData.reservation.updated_at = DateTools.dateTimeZone( resData.reservation.updated_at,  _timezone, 'YYYY-MM-DD HH:mm' )
                    }
                }

                resolve(resData);
            })
            .catch( err => {
                reject(err);
            })
        })
    },

    get_Reservations (context, settings)
    {
        return new Promise((resolve, reject) => {

            ReservationAPI.api_Get_Reservations(settings.query)
            .then( res => {
                let resData = res.data
                let _user_ids = []

                if ( resData )
                {
                    if ( resData.reservations )
                    {
                        let _timezone = this.state.user.timezone;  // 取得其他 namespace 的 state

                        resData.reservations.forEach( _reservation => {

                            if ( _user_ids.indexOf( _reservation.principal ) === -1 ) _user_ids.push( _reservation.principal )

                            _reservation.exe_date   = DateTools.dateTimeZone( _reservation.exe_date, _timezone, 'YYYY-MM-DD' )
                            _reservation.due_date   = DateTools.dateTimeZone( _reservation.due_date, _timezone, 'YYYY-MM-DD' )
                            _reservation.created_at = DateTools.dateTimeZone( _reservation.created_at, _timezone, 'YYYY-MM-DD HH:mm' )
                            _reservation.updated_at = DateTools.dateTimeZone( _reservation.updated_at,  _timezone, 'YYYY-MM-DD HH:mm' )

                            _reservation.type_name   = context.getters.map_ReservationType_TO_Name( _reservation.type ).text
                            _reservation.status_name = context.getters.map_ReservationStatus_TO_Name( _reservation.status ).text
                        })
                    }
                }

                // 設定使用者資訊：呼叫 User API 後才返還 res
                if ( _user_ids.length > 0 )
                {
                    const _query = {}
                    _query.ids =_user_ids.join(',')

                    this.dispatch('user/get_Users', { query: _query })
                    .then(  r => {
                        // 把使用者資料填回去
                        let _map_users = this.state.user.map_users;  // 取得其他 namespace 的 state
                        resData.reservations.forEach( _reservation => {
                            if ( _map_users[ _reservation.principal ] !== undefined ) _reservation.principal_data = _map_users[ _reservation.principal ]
                        })

                        resolve(resData);
                    })
                    .catch( e => { resolve(resData); })
                }
                else resolve(resData);
            })
            .catch( err => {
                reject(err);
            })
        })
    },

}

// mutations
const mutations = {

    set_Show_ID (state, id)
    {
        state.show_id = id
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
