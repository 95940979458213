import DateTools from '@/tools/date';
import * as RecordAPI from '@/apis/record';

// initial state
const state = {

  list_sort: [
    { value: '-id', text: 'id 降序' },
    { value: 'id', text: 'id' },

    { value: '-record_time', text: '記錄時間 降序' },
    { value: 'record_time', text: '記錄時間' },

    { value: '-updated_at', text: '更新時間 降序' },
    { value: 'updated_at', text: '更新時間' },
  ],

  list_sources: {
    indeedee: { name: '愛管侍' },
    'indeedee-backend': { name: '愛管侍-後端' },
    rotom: { name: '洛托姆' },
    turtwig: { name: '草苗龜' },
    'turtwig-plus': { name: '草苗加' },
    grotle: { name: '樹林龜' },
  },

  list_actions: [
    { text: '製造', value: 'manufacture', description: '製造中' },
    { text: '組裝', value: 'assemble', description: '料件組裝完成' },
    { text: '維修完成', value: 'fix', description: '維修完成' },
    { text: '入庫', value: 'ready', description: '製造後入庫' },
    { text: '整新入庫', value: 'reborn', description: '維修後入庫，設備標示為整新品入庫' },
    { text: '打包', value: 'package', description: '將要帶去現場/要寄出的設備打包等待運送' },
    { text: '出貨', value: 'ship', description: '運送至案場' },
    { text: '啟用', value: 'activate', description: '現場啟用設備紀錄，將裝置狀態切為：運作中' },
    { text: '回報', value: 'report', description: '客戶回報問題，將裝置狀態切為：調查中' },
    { text: '壞掉', value: 'broken', description: '設備壞掉紀錄，將裝置狀態切為：故障' },
    { text: '帶回', value: 'takeback', description: '現場回收項目紀錄' },
    { text: '要寄回', value: 'sendback', description: '確定要寄回，將裝置狀態切為：待聯繫' },
    { text: '聯繫收件', value: 'recall', description: '聯繫案場人員將設備寄回，將裝置狀態切為：待回收' },
    { text: '收回', value: 'withdraw', description: '檢查收到的寄回項目並紀錄' },
    { text: '送修', value: 'pushqueue', description: '檢測後將設備排入待維修清單' },
    { text: '維修', value: 'repair', description: '維修狀況紀錄，將裝置狀態切為：維修' },
    { text: '現場維修', value: 'hotfix', description: '現場維修狀況紀錄，將裝置狀態切為：運作中' },
    { text: '擱置', value: 'pend', description: '裝置暫時不使用，將裝置狀態切為：休息' },
    { text: '廢棄', value: 'terminate', description: '設備廢棄紀錄，將裝置狀態切為：棄置' },

    { text: '注意', value: 'warn', description: '發現設備狀況需要關注事項' },
    { text: '升級', value: 'upgrade', description: '升級程式或部件紀錄' },
    { text: '紀錄', value: 'record', description: '調查、檢測、維修等過程中的各種紀錄' },
    { text: '測試', value: 'functest', description: '各種測試紀錄' },
    { text: '其他', value: 'other', description: '無法歸類的動作' },
  ],

  list_common_actions: ['warn', 'upgrade', 'record', 'functest', 'other'],

  mapping_action_to_status: [
    ['manufacture', 'planning'],
    ['ready', 'inventory'],
    ['ship', 'shipped'],
    ['repair', 'repairing'],
    ['report', 'malfunction'],
    ['terminate', 'abandon'],
    ['pend', 'resting'],
    ['activate', 'active'],
  ],
  record_sample: {
    common: [
      { title: '原因', text: '原因： [ ]。' },
      { title: '備註', text: '備註： [ ]。' },
      { title: '換行', text: '' },
      { title: '7pupu', text: '吼~ 氣氣777！' },
      { title: '勾選符號', text: '☐ ☑' },
    ],
    manufacture: [
      { title: '預計要做', text: '已確定要製作，先 booking 裝置。' },
      { title: '組裝中', text: '組裝項目： [ ]。' },
      { title: '缺料等待', text: '缺料項目： [ ]。' },
    ],
    assemble: [
      { title: '組裝完成', text: '組裝完成，等待測試。' },
      { title: '硬體趴', text: '硬體趴大家一起組裝。' },
    ],
    fix: [
      { title: '維修完成', text: '維修完成，等待測試。' },
    ],
    ready: [
      { title: '製作完成', text: '製作完成，已入庫。' },
    ],
    reborn: [
      { title: '整新入庫', text: '維修 & 檢測後，轉生為整新品入庫。' },
    ],
    package: [
      { title: '寄件打包', text: '打包內容： [ 充電器 / 角鋼 / 氣象站腳架 ]。' },
      { title: '帶去場佈', text: '與 [ 充電器 / 角鋼 / 氣象站腳架 / 工具箱 ] 一起裝外出籃，準備帶去 [ 地點 ]。' },
    ],
    ship: [
      { title: '已寄送', text: '已寄送，寄貨方式：[ 順豐收件 / 郵局 / 黑貓 ]，包裹單號： [ ]。' },
      { title: '場佈行李', text: '已整理要帶去 [ 地點 ] 進行 [ 安裝 / 更換 ]。' },
    ],
    activate: [
      { title: '啟動', text: '進行啟動程序。' },
      { title: '重開機', text: '進行重開機。' },
    ],
    report: [
      { title: '龜蜜回報', text: '發現問題： [ ]。' },
      { title: '客戶回報', text: '[ 地點 / 專案 ] 的 [ 人 ] 發現問題： [ ]，使用 [ Line / Email / 電話 ] 回報。' },
    ],
    broken: [
      { title: '判斷為故障', text: '經過檢測，判斷為故障。' },
    ],
    takeback: [
      { title: '阿龜維修人員現場帶回', text: '已至 [ 地點 ] 將設備帶回，帶回項目：[]' },
    ],
    sendback: [
      { title: '確定設備需寄回', text: '確認 [ 地點 ] 的設備需寄回，寄回項目：[]' },
      { title: '聯絡-電話', text: '[ 地點 / 專案 ] 的 [ 人 ] ，電話： [ ]。' },
      { title: '聯絡-信箱', text: '[ 地點 / 專案 ] 的 [ 人 ] ，信箱： [ ]。' },
      { title: '聯絡-Line', text: '[ 地點 / 專案 ] 的 [ 人 ] ，Line 名稱： [ ]。' },
      { title: '收件資訊', text: '收件時間： [ ]，地址： [ ]。' },
      { title: '* switch 交換裝置', text: '此設備需寄回，另外安排將 [ 裝置 ] 寄過去 [ 地點 ] 交換。' },
    ],
    recall: [
      { title: '聯繫案場人員寄回', text: '已請 [ 地點 ] 的 [ 人 ] [ 聯絡方式 ] 將設備寄回至阿龜辦公室，需寄回項目：[]，包裹單號：[]。' },
      { title: '派快遞收件', text: '已請 [ 黑貓/順豐 ] 於 [ 日期 ] 至案場收件，預計 [ 日期 ] 送達辦公室。' },
    ],
    withdraw: [
      { title: '已收取客戶寄回設備', text: '已收到包裹，包裹內含項目：[]。' },
    ],
    pushqueue: [
      { title: '送修', text: '經過檢測決定送修，將排入待維修清單。' },
    ],
    repair: [
      { title: '辦公室維修', text: '在阿龜辦公室進行維修。' },
      { title: '維修內容', text: '維修內容： [ ]，已維修完成。' },
    ],
    hotfix: [
      { title: '現場維修', text: '到 [ 地點 ] 進行維修。' },
      { title: '維修內容', text: '維修內容： [ ]，已維修完成。' },
    ],
    pend: [
      { title: '休息', text: '裝置在 [ 地點 ] 因為 [ 休耕 / 不明原因 ] 暫時不使用，設為休息狀態。' },
    ],
    terminate: [
      { title: '檢測後拒修，直接棄置', text: '經過檢測決定直接廢棄。' },
      { title: '檢測後拒修，寄回案場', text: '經過檢測決定不維修，將設備寄回原案場後廢棄。' },
      { title: '停止維護', text: '專案已到期，放棄維護、停止收資料。' },
      { title: '裝置在案場不收回', text: '裝置為買斷，在案場不收回。' },
      { title: '無法維修', text: '判斷為無法維修狀態，故直接棄置。' },
    ],

    // common actions
    warn: [
      { title: '數值異常', text: '觀察發現異常項目： [ ]。' },
    ],
    upgrade: [
      { title: '軟體升級', text: '軟體升級版本： [ ]。' },
      { title: '軟體 hotfix', text: '軟體 hotfix 版本： [ ]。' },
    ],
    record: [
      // investigate
      { title: '等待調查', text: '收到注意提醒，等待釐清發生事情。' },
      { title: '調查/檢測無異常', text: '調查/檢測無異常。' },
      { title: '驗貨異常!!', text: '驗貨異常，須送回亞力檢測。' },
      { title: '調查紀錄', text: '調查項目：[]，結果：[ 人為疏失造成... / 裝置發生...故障 ]。' },
      { title: '靈異現象', text: '目前查不出原因的靈異現象。' },

      // check issue/bug 調查
      { title: '聯絡-電話', text: '[ 地點 / 專案 ] 的 [ 人 ] ，電話： [ ]。' },
      { title: '聯絡-信箱', text: '[ 地點 / 專案 ] 的 [ 人 ] ，信箱： [ ]。' },
      { title: '聯絡-Line', text: '[ 地點 / 專案 ] 的 [ 人 ] ，Line 名稱： [ ]。' },
      { title: '現場檢查', text: '在 [ 地點 / 專案 ] 進行 [ ] 檢查。' },
      { title: '指示客戶檢查', text: '指導 [ 地點 / 專案 ] 的 [ 人 ] 進行 [ ] 檢查。' },

      { title: '檢測紀錄', text: '檢測項目： [ ]，結果：[ ]。' },
      { title: '訊號不良', text: '訊號不良： [ 距離太遠 / 金屬遮蔽 / 樹木建物遮蔽 / sim 卡過期 ]。' },
      { title: '電力不足', text: '電力不足： [ 光照不足 / 作物遮蔽 / 電池損壞 / 太陽能板太髒 / 潮濕導致電力不穩 ]。' },
      { title: '部件損壞', text: '部件損壞： [ 電線斷掉 / 機具打壞 / 零件遺失 ]。' },

      // repair
      { title: '維修紀錄', text: '維修項目：[]，結果：[ 已 / 未 ] 修復。' },

      // check brfore ship
      { title: '出貨檢查', text: '☐ ☑ 1. 已綁定於期作「」 \n☐ ☑ 2. 狀態為「非使用中」，電量為100％，各項參數都有資料 \n☐ ☑ 3. 已貼上hashid 以及 suid \n☐ ☑ 4. 資料在愛管侍中，電量為 ，所需參數有資料' },
    ],
    functest: [
      { title: '辦公室測試', text: '在阿龜辦公室進行測試。' },
      { title: '測試項目', text: '測試項目： [ ]。' },
      { title: '測試電力', text: '測試電力是否 [ 可充電 / 通電 ]。' },
      { title: '測試數值', text: '測試數值： [ 正常 / 不穩 / 錯誤 ]。' },
      { title: '測試訊號', text: '測試訊號： [ 良好 / 不好 / 勉強 ]。' },
      { title: '測試OK', text: '測試 OK。' },
      { title: '測試Err', text: '測試不成功，推測原因： [ ]。' },
      { title: '測試環境', text: '測試環境：在 [ 地點 ] 進行測試，使用 [ 4G / LoRa / nbioT / 辦公室 ] 網路。' },
      { title: '測試方法', text: '測試方法： [ ]。' },
    ],
    other: [],
  },
};

// getters
const getters = {

  map_Action_TO_Status: (state) => {
    const _mapping = {};
    state.mapping_action_to_status.forEach((_map) => {
      _mapping[_map[0]] = _map[1];
    });
    return _mapping;
  },

  map_Status_TO_Action: (state) => {
    const _mapping = {};
    state.mapping_action_to_status.forEach((_map) => {
      _mapping[_map[1]] = _map[0];
    });
    return _mapping;
  },
};

// actions
const actions = {

  get_Record_Data(context, settings) {
    return new Promise((resolve, reject) => {
      RecordAPI.api_Get_Record(settings.params)
        .then((res) => {
          const resData = res.data;

          if (resData) {
            if (resData.record) {
              const _timezone = this.state.user.timezone; // 取得其他 namespace 的 state

              resData.record.record_time = DateTools.dateTimeZone(resData.record.record_time, _timezone, 'YYYY-MM-DD HH:mm');
              resData.record.updated_at = DateTools.dateTimeZone(resData.record.updated_at, _timezone, 'YYYY-MM-DD HH:mm');
            }
          }

          resolve(resData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  get_Device_Last_Records(context, settings) {
    return new Promise((resolve, reject) => {
      RecordAPI.api_Get_Records(settings.query)
        .then((res) => {
          const resData = res.data;

          if (resData) {
            const _timezone = this.state.user.timezone; // 取得其他 namespace 的 state

            settings.devices.forEach((_hashid) => {
              if (resData[_hashid]) {
                resData[_hashid].forEach((_record) => {
                  _record.record_time = DateTools.dateTimeZone(_record.record_time, _timezone, 'YYYY-MM-DD');
                  _record.updated_at = DateTools.dateTimeZone(_record.updated_at, _timezone, 'YYYY-MM-DD HH:mm');
                });
              }
            });
          }

          resolve(resData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  get_Records(context, settings) {
    return new Promise((resolve, reject) => {
      RecordAPI.api_Get_Records(settings.query)
        .then((res) => {
          const resData = res.data;
          const _user_ids = [];

          if (resData) {
            if (resData.records) {
              const _timezone = this.state.user.timezone; // 取得其他 namespace 的 state

              resData.records.forEach((_record) => {
                if (_user_ids.indexOf(_record.executor) === -1) _user_ids.push(_record.executor);
                if (_user_ids.indexOf(_record.recorder) === -1) _user_ids.push(_record.recorder);

                _record.record_time = DateTools.dateTimeZone(_record.record_time, _timezone, 'YYYY-MM-DD HH:mm');
                _record.updated_at = DateTools.dateTimeZone(_record.updated_at, _timezone, 'YYYY-MM-DD HH:mm');
              });
            }
          }

          // 設定使用者資訊：呼叫 User API 後才返還 res
          if (_user_ids.length > 0) {
            const _query = {};
            _query.ids = _user_ids.join(',');

            this.dispatch('user/get_Users', { query: _query })
              .then((r) => {
                // 把使用者資料填回去
                const _map_users = this.state.user.map_users; // 取得其他 namespace 的 state
                resData.records.forEach((_record) => {
                  if (_map_users[_record.executor] !== undefined) _record.executor_data = _map_users[_record.executor];
                  if (_map_users[_record.recorder] !== undefined) _record.recorder_data = _map_users[_record.recorder];
                });

                resolve(resData);
              })
              .catch((e) => { resolve(resData); });
          } else resolve(resData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
