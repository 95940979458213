import request from './_https';
// querystring 在 node 14 後已經棄用，改為 URLSearchParams
import setQueryString from '../tools/helpers';

/** --------------------
 *  Normal
 *  ----------------- */

export const api_Get_Gateways = (query = undefined) => request('get', `/gateways${setQueryString(query)}`);
export const api_Get_Gateway_Detail = (params) => request('get', `/gateways/${params.hashID}`);
export const api_Update_Gateway = (params, data) => request('put', `/gateways/${params.hashID}`, data);
export const api_Get_Gateway_Records = (params, query = undefined) => request('get', `/gateways/${params.hashID}/records${setQueryString(query)}`);

export const api_Store_Gateway_Pic = (params, data) => {
  const _formData = new FormData();
  _formData.append('pic', data.pic);

  const _config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  return request('post', `/gateways/${params.hashID}/pic`, _formData, _config);
};

/** --------------------
 *  Admin
 *  ----------------- */

export const api_Create_Gateway = (data) => request('post', '/admin/gateways', data);
export const api_Delete_Gateway = (params) => request('delete', `/admin/gateways/${params.hashID}`);

/** --------------------
 *  Gateway Whitelist
 *  ----------------- */

export const api_Get_GatewayWhitelist_JSON = (params) => request('get', `/gateways/${params.hashID}/whitelist/${params.gatewayID}`);
export const api_Get_GatewayWhitelist = (query = undefined) => request('get', `/gateway_whitelists${setQueryString(query)}`);

export const api_Update_GatewayWhitelist = (data) => request('put', '/admin/gateway_whitelists', data);
export const api_Update_GatewayWhitelistChannel = (params, data) => request('put', `/admin/gateway_whitelists/${params.whitelistID}`, data);

/** --------------------
 *  Gateway Logs
 *  ----------------- */

export const api_Get_Gateway_Logs = (params) => request('get', `/gateways/${params.hashID}/logs`);
