
// initial state
const state = {

    list_material_types: [
        'sensor', 'power', 'hardware', 'additional', 'other',
    ],

    list_material_items: {
        'soil_3_in_1':                  { type: 'sensor', name:　'土壤三合一' },
        'soil_ph':                      { type: 'sensor', name:　'土壤PH' },
        'solar_lux':                    { type: 'sensor', name:　'光照度 LUX' },
        'solar_par':                    { type: 'sensor', name:　'光照度 PAR' },
        'air_temperature_humidity':     { type: 'sensor', name:　'空氣溫溼度' },
        'air_co2':                      { type: 'sensor', name:　'CO2' },
        'air_nh3':                      { type: 'sensor', name:　'氨氣' },
        'bug_box':                      { type: 'sensor', name:　'蟲罐' },
        'camera':                       { type: 'sensor', name:　'鏡頭' },

        'charger_version':              { type: 'power', name:　'充電版' },
        'solar_panel_5w_5v':            { type: 'power', name:　'太陽能板 5W (5V)' },
        'solar_panel_8w_5v':            { type: 'power', name:　'太陽能板 8W (5V)' },
        'solar_panel_15w_5v':           { type: 'power', name:　'太陽能板 15W (5V)' },
        'solar_panel_15w_12v':          { type: 'power', name:　'太陽能板 15W (12V)' },
        '18650':                        { type: 'power', name:　'18650 電池',       quantity: 1 },
        '18650_x3':                     { type: 'power', name:　'18650 電池 3 顆',  quantity: 3,    alias: '18650' },
        'battery_pack_5v':              { type: 'power', name:　'電池包 5V' },
        'battery_pack_12v':             { type: 'power', name:　'電池包 12V' },

        'sim':                          { type: 'hardware', name:　'SIM 卡' },
        '4g_router':                    { type: 'hardware', name:　'4G router' },
        'usb_dongle':                   { type: 'hardware', name:　'USB dongle' },
        'wifi_dongle':                  { type: 'hardware', name:　'Wifi dongle' },
        'ups':                          { type: 'hardware', name:　'UPS' },
        'antenna_big':                  { type: 'hardware', name:　'大天線' },
        'watertight_case':              { type: 'hardware', name:　'防水箱' },
        'wire_rope':                    { type: 'hardware', name:　'鋼索 6 公尺' },
        'wire_rope_clamp':              { type: 'hardware', name:　'鋼索夾',        quantity: 1 },
        'wire_rope_clamp_x15':          { type: 'hardware', name:　'鋼索夾 15 個',  quantity: 15,   alias: 'wire_rope_clamp' },
        'tightener':                    { type: 'hardware', name:　'緊迫器',        quantity: 1 },
        'tightener_x3':                 { type: 'hardware', name:　'緊迫器 3 個',   quantity: 3,    alias: 'tightener' },
        'pegs':                         { type: 'hardware', name:　'營釘',          quantity: 1 },
        'pegs_x6':                      { type: 'hardware', name:　'營釘 6 支',     quantity: 6,    alias: 'pegs' },
        'weatherbox_tripod':            { type: 'hardware', name:　'氣象站腳架' },
        'rack_30cm':                    { type: 'hardware', name:　'洞洞角鋼(立柱) 30 cm',      quantity: 1 },
        'rack_30cm_x2':                 { type: 'hardware', name:　'洞洞角鋼(立柱) 30 cm 2 個', quantity: 2,  alias: 'rack_30cm' },
        'rack_90cm':                    { type: 'hardware', name:　'洞洞角鋼(立柱) 90 cm',      quantity: 1 },
        'rack_120cm':                   { type: 'hardware', name:　'洞洞角鋼(立柱) 120 cm',     quantity: 1 },

        '18650_charger':                { type: 'additional', name:　'18650 電池充電器' },
        'battery_pack_charging_cable':  { type: 'additional', name:　'電池包充電線' },
        'battery_pack_transformer_5v':  { type: 'additional', name:　'電池包充電變壓器 5V' },
        'battery_pack_transformer_12v': { type: 'additional', name:　'電池包充電變壓器 12.6V' },

        'control':                      { type: 'other', name:　'控制' },
        'repair':                       { type: 'other', name:　'維修' },
        'update_software':              { type: 'other', name:　'升級軟體' },
        'update_hardware':              { type: 'other', name:　'升級硬體' },
        'other':                        { type: 'other', name:　'未列出特殊項目' },
    },

    // 套餐 - 常用項目組
    list_material_menu: [
        { text: '增加材料數量',         category: 'other',       type: 'MAT', materials: [] },
        { text: '常用土讓三合一 01',    category: 'wand',        type: 'FA',  materials: [ 'soil_3_in_1', 'solar_panel_5w_5v', 'rack_30cm', 'rack_90cm', 'battery_pack_5v' ] },
        { text: '常用 Gateway 01',      category: 'gateway',     type: 'GW',  materials: [ 'sim', '4g_router' ] },
        { text: '常用 氣象站 01',       category: 'weatherbox',  type: 'WE',  materials: [ 'weatherbox_tripod', 'rack_30cm_x2', 'wire_rope', 'wire_rope_clamp_x15', 'tightener_x3', 'pegs_x6' ] },
        { text: '常用 縮時攝影 01',     category: 'camera',      type: 'CB',  materials: [ 'solar_panel_5w_5v', 'rack_30cm', 'rack_90cm', 'battery_pack_5v' ] },
        { text: '常用 無線熱點 01',     category: 'accesspoint', type: 'AA',  materials: [ 'solar_panel_5w_5v', 'rack_30cm', 'rack_90cm', 'battery_pack_5v' ] },
    ],
    
}
    
   

// getters
const getters = {
  
}

// actions
const actions = {

}

// mutations
const mutations = {
    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}