export default {

    check_ShowUI_ProjectEnd (status, is_admin)
    {
        if (is_admin) return true;
        if (status === 'activate') return true;
        return false;
    },

    /**
     * check_ShowUI：檢查 [ 專案服務等級 ] 可以看到的畫面
     * @param {String} service_level
     * @param {String} showItem
     */
    check_ShowUI (service_level, showItem)
    {
        switch (service_level)
        {
            case 'basic':      return this._check_Service_Level_basic(showItem);
            case 'premium':    return this._check_Service_Level_premium(showItem);
            case 'education':  return this._check_Service_Level_education(showItem);

            default: return false;
        }
    },

    // 基礎版
    _check_Service_Level_basic (showItem)
    {
        switch (showItem)
        {
            case 'dashboard_stages':            return false;

            case 'history_download':            return true;
            case 'history_charts':              return false;
            case 'history_charts_compare':      return false;

            default: return false;
        }
    },
    // 完整版
    _check_Service_Level_premium (showItem)
    {
        switch (showItem)
        {
            case 'dashboard_stages':            return true;

            case 'history_download':            return true;
            case 'history_charts':              return true;
            case 'history_charts_compare':      return true;

            default: return false;
        }
    },
    // 專業版 / 教育版
    _check_Service_Level_education (showItem)
    {
        switch (showItem)
        {
            case 'dashboard_stages':            return true;

            case 'history_download':            return true;
            case 'history_charts':              return false;
            case 'history_charts_compare':      return false;

            default: return false;
        }
    },

    /**
     * check_ShowUI_byRole：檢查 [ 專案角色 ] 可以看到的畫面
     * @param {String} role
     * @param {String} showItem
     */
    check_ShowUI_byRole (role, showItem)
    {
        switch (role)
        {
            case 'admin':          return true;
            case 'beehive':        return this._check_role_beehive(showItem);
            case 'project_owner':  return this._check_role_project_owner(showItem);
            case 'project_member': return this._check_role_project_member(showItem);
            case 'researcher':     return this._check_role_researcher(showItem);

            default: return false;
        }
    },

    _check_role_beehive (showItem)
    {
        switch (showItem)
        {
            case 'data_count': return true;
            case 'sensor_params': return true;
            case 'update_frequency': return true;

            case 'add_member': return true;
            default: return false;
        }
    },
    _check_role_project_owner (showItem)
    {
        switch (showItem)
        {
            case 'add_member': return true;
            default: return false;
        }
    },
    _check_role_project_member (showItem)
    {
        switch (showItem)
        {
            case 'add_member': return false;
            default: return false;
        }
    },
    _check_role_researcher (showItem)
    {
        switch (showItem)
        {
            case 'add_member': return false;
            default: return false;
        }
    },
}
