

import DateTools from '@/tools/date'
import * as ShipmentAPI from '@/apis/shipment'

// initial state
const state = {

    list_sort: [
        { value: '-id',                 text: 'id 降序' },
        { value: 'id',                  text: 'id' },

        { value: '-shipment_date',      text: '出貨日期 降序' },
        { value: 'shipment_date',       text: '出貨日期' },
    ],

}



// getters
const getters = {

}

// actions
const actions = {

    get_Shipment_Detail (context, settings)
    {
        return new Promise((resolve, reject) => {

            ShipmentAPI.api_Get_Shipment_Detail(settings.params)
            .then( res => {
                let resData = res.data

                if ( resData )
                {
                    if ( resData.shipment )
                    {
                        let _timezone = this.state.user.timezone;  // 取得其他 namespace 的 state

                        resData.shipment.shipment_date = DateTools.dateTimeZone( resData.shipment.shipment_date, _timezone, 'YYYY-MM-DD' )
                        resData.shipment.created_at = DateTools.dateTimeZone( resData.shipment.created_at, _timezone, 'YYYY-MM-DD HH:mm' )
                        resData.shipment.updated_at = DateTools.dateTimeZone( resData.shipment.updated_at,  _timezone, 'YYYY-MM-DD HH:mm' )
                    }
                }

                resolve(resData);
            })
            .catch( err => {
                reject(err);
            })
        })
    },

    get_Shipments (context, settings)
    {
        return new Promise((resolve, reject) => {

            ShipmentAPI.api_Get_Shipments(settings.query)
            .then( res => {
                let resData = res.data
                let _user_ids = []

                if ( resData )
                {
                    if ( resData.shipments )
                    {
                        let _timezone = this.state.user.timezone;  // 取得其他 namespace 的 state

                        resData.shipments.forEach( _shipment => {

                            if ( _user_ids.indexOf( _shipment.recorder ) === -1 ) _user_ids.push( _shipment.recorder )

                            _shipment.shipment_date = DateTools.dateTimeZone( _shipment.shipment_date, _timezone, 'YYYY-MM-DD' )
                            _shipment.created_at = DateTools.dateTimeZone( _shipment.created_at, _timezone, 'YYYY-MM-DD HH:mm' )
                            _shipment.updated_at = DateTools.dateTimeZone( _shipment.updated_at,  _timezone, 'YYYY-MM-DD HH:mm' )
                        })
                    }
                }

                // 設定使用者資訊：呼叫 User API 後才返還 res
                if ( _user_ids.length > 0 )
                {
                    const _query = {}
                    _query.ids =_user_ids.join(',')

                    this.dispatch('user/get_Users', { query: _query })
                    .then(  r => {
                        // 把使用者資料填回去
                        let _map_users = this.state.user.map_users;  // 取得其他 namespace 的 state
                        resData.shipments.forEach( _shipment => {
                            if ( _map_users[ _shipment.recorder ] !== undefined ) _shipment.recorder_data = _map_users[ _shipment.recorder ]
                        })

                        resolve(resData);
                    })
                    .catch( e => { resolve(resData); })
                }
                else resolve(resData);
            })
            .catch( err => {
                reject(err);
            })
        })
    },

}

// mutations
const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
