
export default {

    split_String (text)
    {
        if ( text !== undefined && text !== '' )
        {
            return text.split(/([, \n\t@$!])/)
        }
        return []
    },

    check_Type_Array (value)
    {
        return value instanceof Array;
    },

    set_NullableValue (value)
    {
        if ( value !== undefined && value !== '' ) return value
        else return null
    },

    deepCopy_object (origin_obj)
    {
        if (origin_obj)
            return JSON.parse( JSON.stringify(origin_obj) );
        else
            return undefined
    },

    show_jsonString (origin_obj)
    {
        let _result = '';
        if (origin_obj)
        {
            _result = JSON.stringify( origin_obj );
            _result = _result.replace(/\[/gi, "\[ ");     // 將所有的 [ 都加上空格
            _result = _result.replace(/\]/gi, " \]");     // 將所有的 ] 都加上空格
            _result = _result.replace(/\{/gi, "\{ ");     // 將所有的 { 都加上空格
            _result = _result.replace(/\}/gi, " \}");     // 將所有的 } 都加上空格
            _result = _result.replace(/,/gi,  ", ");      // 將所有的 , 都加上空格
            _result = _result.replace(/:/gi,  ": ");      // 將所有的 : 都加上空格
        }
        // console.log( _result );
        return _result;
    },

}
