import request from './_https';
// querystring 在 node 14 後已經棄用，改為 URLSearchParams
import setQueryString from '../tools/helpers';

/** --------------------
 *  照片
 *  ----------------- */
export const api_Get_Photos = (query = undefined) => request('get', `/photos${setQueryString(query)}`);
export const api_Delete_Photo = (params) => request('delete', `/admin/photos/${params.photoID}`);

/** --------------------
 *  縮時影片
 *  ----------------- */
export const api_Get_Timelapses = (query = undefined) => request('get', `/timelapses${setQueryString(query)}`);
export const api_Update_Timelapses = (params, data) => request('put', `/admin/timelapses/${params.timelapseID}`, data); // 重新產生縮時影片
export const api_Delete_Timelapse = (params) => request('delete', `/admin/timelapses/${params.timelapseID}`);
