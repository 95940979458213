import request from './_https';
// querystring 在 node 14 後已經棄用，改為 URLSearchParams
import setQueryString from '../tools/helpers';

/** --------------------
 *  Normal
 *  ----------------- */

export const api_Get_Devices = (query = undefined) => request('get', `/devices${setQueryString(query)}`);
export const api_Get_Device_Detail = (params) => request('get', `/devices/${params.hashID}`);
export const api_Update_Device = (params, data) => request('put', `/devices/${params.hashID}`, data);
export const api_Get_Device_Records = (params, query = undefined) => request('get', `/devices/${params.hashID}/records${setQueryString(query)}`);

export const api_Store_Device_Pic = (params, data) => {
  const _formData = new FormData();
  _formData.append('pic', data.pic);

  const _config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  return request('post', `/devices/${params.hashID}/pic`, _formData, _config);
};

/** --------------------
 *  Admin
 *  ----------------- */

export const api_Create_Device = (data) => request('post', '/admin/devices', data);
export const api_Delete_Device = (params) => request('delete', `/admin/devices/${params.hashID}`);
