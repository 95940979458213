import Vue from 'vue'
import VueI18n from 'vue-i18n'

// 自訂語言檔
import en from '@/i18n/lang/lang_en'
import tw from '@/i18n/lang/lang_tw'
import jp from '@/i18n/lang/lang_jp'

// 使用插件
Vue.use(VueI18n)

// 取得預設語系
const locale = localStorage.getItem('locale') || 'zh-Hant-TW'

// 建立 VueI18n 實體
const i18n = new VueI18n({
  locale,
  messages: {
    'en': en,
    'zh-Hant-TW':　tw,
    'ja': jp,
  }
})

export default i18n