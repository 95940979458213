import { omitBy, isUndefined, isNull } from "lodash";

export default function setQueryString(query = undefined) {
  if (query) {
    // 排除 undifine 和 Null 後重新組合字串
    return `?${new URLSearchParams(
      omitBy(query, isUndefined || isNull)
    ).toString()}`;
  }
  return "";
}
